import { getCSRFToken } from './csrf';
import { getSessionToken } from "@shopify/app-bridge-utils";
import createApp from "@shopify/app-bridge";

let sessionToken:any;

export async function getFetch(url:string, body:any) {
  try {
    await getSessionTokenFromAppBridge();
  } catch (e) {
    return(e);
  }
  url = addSlashToURLWhereNecessary(url);
  return fetch(url, {
      credentials: 'include',
      method: 'GET',
      mode: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCSRFToken(),
        'Authorization': 'Bearer ' + sessionToken
      },
  });
}
export async function patchFetch(url:string, body:any) {
  try {
    await getSessionTokenFromAppBridge();
  } catch (e) {
    return(e);
  }
  url = addSlashToURLWhereNecessary(url);
  return fetch(url, {
      credentials: 'include',
      method: 'PATCH',
      mode: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCSRFToken(),
        'Authorization': 'Bearer ' + sessionToken
      },
      body: JSON.stringify(body)
  });
}

export async function postFetch(url:string, body:any) {
  try {
    await getSessionTokenFromAppBridge();
  } catch (e) {
    return(e);
  }
  url = addSlashToURLWhereNecessary(url);
    return fetch(url, {
        credentials: 'include',
        method: 'POST',
        mode: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCSRFToken(),
          'Authorization': 'Bearer ' + sessionToken
        },
        body: JSON.stringify(body)
    });
}

export async function putFetch(url:string, body:any) {
  try {
    await getSessionTokenFromAppBridge();
  } catch (e) {
    return(e);
  }
  url = addSlashToURLWhereNecessary(url);
    return fetch(url, {
        credentials: 'include',
        method: 'PUT',
        mode: 'same-origin',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCSRFToken(),
          'Authorization': 'Bearer ' + sessionToken
        },
        body: JSON.stringify(body)
    });
}

export async function postUploadFetch(url:string, body:any) {
  try {
    await getSessionTokenFromAppBridge();
  } catch (e) {
    return(e);
  }
  url = addSlashToURLWhereNecessary(url);
  return fetch(url, {
      credentials: 'include',
      method: 'POST',
      mode: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'X-CSRFToken': getCSRFToken(),
        'Authorization': 'Bearer ' + sessionToken
      },
      body: body
  });
}

export async function deleteFetch(url:string, body:any) {
  try {
    await getSessionTokenFromAppBridge();
  } catch (e) {
    return(e);
  }
  url = addSlashToURLWhereNecessary(url);
  return fetch(url, {
      credentials: 'include',
      method: 'DELETE',
      mode: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': getCSRFToken(),
        'Authorization': 'Bearer ' + sessionToken
      },
      body: JSON.stringify(body)
  });
}

function getSessionTokenFromAppBridge() {
  return new Promise((resolve, reject) => {
    let shopHost = new URLSearchParams(window.location.search).get("host");
    if (shopHost) {
      try {
        localStorage.setItem('shopHost', shopHost);
      } catch(err) {
        (window as any).name = shopHost;
      }
    } else {
      try {
        let shopHostFromStorage = localStorage.getItem('shopHost');
        if (shopHostFromStorage) {
          shopHost = shopHostFromStorage;
        }
      } catch(err) {
        let shopHostFromStorage = (window as any).name;
        if (shopHostFromStorage) {
          shopHost = shopHostFromStorage;
        }
      }
    }

    fetch('/app/api/shopify/non-auth/', {})
    .then(res => res.json()
    ).then(data => {
      (window as any).apiKey = data.api_key;
      
      const app:any = createApp({
        host:shopHost!,
        apiKey: data.api_key,
      });

      if (!inIframe()) {
        reject({error: 'not embedded'});
      }
      
      getSessionToken(app).then(
        (d) => {
          sessionToken = d;
          resolve(d);
        });
      }).catch((err)=> {
        console.log(err);
      });
  });
}

function inIframe() {
  try {
      return window.self !== window.top;
  } catch (e) {
      return true;
  }
}

function addSlashToURLWhereNecessary(url: string) {
  if (!url.endsWith('/') && url.indexOf('?') === -1) {
    return url + '/';
  } else {
    return url;
  }
}
import React, { Component } from 'react';
import './AutocompleteTags.css';
import { Autocomplete, TextContainer, Stack, Tag, TextStyle} from '@shopify/polaris';

interface AutocompleteTagsProps {
    options: any,
    placeholderText:string,
    label:string,
    onChange: Function,
    selected: Array<string>,
    listTitle: string,
    helpText: string
}

interface AutocompleteTagsState {
    selected: Array<string>,
    inputText: string,
    options: any
}

class AutocompleteTags extends Component <AutocompleteTagsProps, AutocompleteTagsState> {
    
    constructor(props: AutocompleteTagsProps) {
        super(props);
        this.state = {
            selected: this.props.selected,
            inputText: '',
            options: this.props.options,
        };
    }

    render() {
      const textField = (
        <Autocomplete.TextField
          onChange={this.updateText}
          onFocus={this.onFocus}
          label=""
          value={this.state.inputText}
          placeholder={this.props.options.length>0 ? this.props.placeholderText : 'None available'}
        />
      );
      return (
        <div>
          <p>{this.props.label}</p>
          <TextContainer>
            <Stack>{this.renderTags()}</Stack>
          </TextContainer>
          <br />
          <Autocomplete
            allowMultiple
            options={this.state.options}
            selected={this.state.selected}
            textField={textField}
            onSelect={this.updateSelection}
            listTitle={this.props.listTitle}
          />
          <TextStyle variation="subdued">{this.props.helpText}</TextStyle>
        </div>
      );
    }

    onFocus = () => {
        this.setState({
            options: this.props.options,
        });
    }
  
    updateText = (newValue:string) => {
      this.setState({inputText: newValue});
      this.filterAndUpdateOptions(newValue);
    };
  
    removeTag = (tag:string) => {
      let newSelected = this.state.selected;
      newSelected = newSelected.filter(t => t !== tag);
      this.setState({selected: newSelected});
      this.props.onChange(newSelected);
    };
  
    renderTags = () => {
      return this.state.selected.map((option) => {
        let tagLabel = '';
        if (typeof(option) === 'string') {
          tagLabel = option.replace('_', ' ');
        } else if (this.props.options.length>0) {
          tagLabel = this.props.options.find((x:any) => x.value === option).label;
        }
        return (
          <Tag key={'option' + option} onRemove={() => this.removeTag(option)}>
            {tagLabel}
          </Tag>
        );
      });
    };
  
    filterAndUpdateOptions = (inputString:string) => {
      if (inputString === '') {
        this.setState({
          options: this.props.options,
        });
        return;
      }
  
      const filterRegex = new RegExp(inputString, 'i');
      const resultOptions = this.props.options.filter((option:any) =>
        option.label.match(filterRegex),
      );
      let endIndex = resultOptions.length - 1;
      if (resultOptions.length === 0) {
        endIndex = 0;
      }
      this.setState({
        options: resultOptions,
      });
    };
  
    updateSelection = (selected:string[]) => {
        this.setState({selected});
        this.props.onChange(selected);
    };
  }

  export default AutocompleteTags;
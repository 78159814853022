import React, { Component } from 'react';
import { Card, FormLayout, Stack, Select, Checkbox } from '@shopify/polaris';
import ColorPicker from './ColorPicker';

interface ThemeEditorProps {
    onThemeChange: Function,
    theme: any,
    style: string,
    desktopType: string,
    mobileType: string,
    defaultColor: any,
    defaultBgColor: any,
    defaultFontWeight: string,
    activeColor: any,
    activeBgColor: any,
    activeFontWeight: string,
    strokeColor: any,
    bgColor: any,
    textColor: any,
    iconType: string,
    iconDefaultColor: any,
    iconActiveColor: any,
    accordionDefaultCollapsed: boolean,
    accordionAllowMultipleExpand: boolean,
    accordionDefaultExpandAll: boolean
}

class ThemeEditor extends Component <ThemeEditorProps> {

    styleOptions = [{ label: 'Normal', value: 'normal' }, { label: 'Underline', value: 'underline' }, { label: 'Link', value: 'link' }, { label: 'Pill', value: 'pill' }, { label: 'Box', value: 'box' }];
    desktopTypeOptions = [{ label: 'Tabs', value: 'tabs' }, { label: 'Accordion', value: 'accordion' }];
    mobileTypeOptions = [{ label: 'Accordion', value: 'accordion' }, { label: 'Tabs', value: 'tabs' }];
    iconTypeOptions = [{ label: 'Arrow', value: 'arrow' }, { label: 'Plus / Minus', value: 'plus' }];
    fontWeightOptions = [{ label: 'Normal', value: 'normal' }, { label: 'Bold', value: 'bold' }];

    handleThemeChange = (selected:any, id:string) => {
        this.props.onThemeChange(selected, id);
    }

    render() {
        return (
            <div>
                <Card title="Overall Style">
                    <Card.Section>
                        <FormLayout>
                            <Select label="" id="style" options={this.styleOptions} onChange={this.handleThemeChange} value={this.props.style} />
                          </FormLayout>
                    </Card.Section>
                    <Card.Section title="Display Type">
                        <FormLayout>
                            <Stack distribution="fillEvenly">
                              <Select id="desktopType" label="Desktop" options={this.desktopTypeOptions} onChange={this.handleThemeChange} value={this.props.desktopType} />
                              <Select id="mobileType" label="Mobile" options={this.mobileTypeOptions} onChange={this.handleThemeChange} value={this.props.mobileType} />
                            </Stack>
                          </FormLayout>
                    </Card.Section>
                    </Card>
                    <Card title="Adjust Styles">
                    <Card.Section title="Default Tab">
                    <Stack>
                          <ColorPicker id="defaultColor" onChange={this.handleThemeChange} label="Title Color" color={this.props.defaultColor}></ColorPicker>
                          <ColorPicker id="defaultBgColor" onChange={this.handleThemeChange} label="Background Color" color={this.props.defaultBgColor}></ColorPicker>
                          <Select id="defaultFontWeight" label="Font Weight" options={this.fontWeightOptions} onChange={this.handleThemeChange} value={this.props.defaultFontWeight} /> 
                        </Stack>
                    </Card.Section>
                    <Card.Section title="Active Tab">
                    <Stack>
                          <ColorPicker id="activeColor" onChange={this.handleThemeChange} label="Title Color" color={this.props.activeColor}></ColorPicker>
                          <ColorPicker id="activeBgColor" onChange={this.handleThemeChange} label="Background Color" color={this.props.activeBgColor}></ColorPicker>
                          <Select id="activeFontWeight" label="Font Weight" options={this.fontWeightOptions} onChange={this.handleThemeChange} value={this.props.activeFontWeight} /> 
                    </Stack>
                    </Card.Section>
                    <Card.Section title="General Colors">
                    <Stack>
                          <ColorPicker id="strokeColor" onChange={this.handleThemeChange} label="Stroke Color" color={this.props.strokeColor}></ColorPicker>
                          <ColorPicker id="bgColor" onChange={this.handleThemeChange} label="Background Color" color={this.props.bgColor}></ColorPicker>
                          <ColorPicker id="textColor" onChange={this.handleThemeChange} label="Text Color" color={this.props.textColor}></ColorPicker>
                      </Stack>
                    </Card.Section>
                    </Card>
                    <Card title="Accordion Icons">
                    <Card.Section>
                      <FormLayout>
                       <Stack>
                          <ColorPicker id="iconDefaultColor" onChange={this.handleThemeChange} label="Icon Default Color" color={this.props.iconDefaultColor}></ColorPicker>
                          <ColorPicker id="iconActiveColor" onChange={this.handleThemeChange} label="Icon Active Color" color={this.props.iconActiveColor}></ColorPicker>
                          <Select id="iconType" label="Icon Type" options={this.iconTypeOptions} onChange={this.handleThemeChange} value={this.props.iconType} /> 
                        </Stack> 
                        </FormLayout>
                    </Card.Section>
                    <Card.Section title="Options">
                        <Checkbox id="accordionDefaultCollapsed" label="Collapse all accordion panels at the beginning" onChange={this.handleThemeChange} checked={this.props.accordionDefaultCollapsed} />

                        <Checkbox id="accordionAllowMultipleExpand" label="Allows multiple panels to be expanded at the same time" onChange={this.handleThemeChange} checked={this.props.accordionAllowMultipleExpand} />

                        {this.props.theme.accordionAllowMultipleExpand && !this.props.theme.accordionDefaultCollapsed &&
                        <Checkbox id="accordionDefaultExpandAll" label="Expand all accordion panels at the beginning" onChange={this.handleThemeChange} checked={this.props.accordionDefaultExpandAll} />
                        }
                    </Card.Section>
                </Card>
            </div>
        );
    }
}

export default ThemeEditor;
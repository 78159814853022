import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import './DragAndDropTable.css';

interface DragAndDropTableProps {
    headers: Array<string>,
    rows: Array<any>,
    percentageWidths: Array<number>,
    onOrderChange: Function
}

interface DragAndDropTableState {
    rows: Array<any>,
}

// a little function to help us with reordering the result
const reorder = (list:Array<any>, startIndex:number, endIndex:number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getItemStyle = (isDragging:boolean, draggableStyle:any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "#f4f5fa" : "white",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver:boolean) => ({
  background: isDraggingOver ? "#eee" : "#eee",
  padding: grid
});


class DragAndDropTable extends Component <DragAndDropTableProps, DragAndDropTableState> {
  constructor(props:DragAndDropTableProps) {
    super(props);
    this.state = {
        rows: this.props.rows
    }
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidUpdate() {
    if (this.state.rows !== this.props.rows) {
      this.setState({rows: this.props.rows});
    }
  }

  getRowHeaderStyle = (index: number) => ({
    width: this.props.percentageWidths[index] + '%'
  });

  getRowWidthStyle = (isDragging:boolean, draggableStyle:any, index: number) => ({
    width: isDragging ? this.props.percentageWidths[index] / 100 * draggableStyle.width : this.props.percentageWidths[index] + '%'
  });

  onDragEnd(result:any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const rows = reorder(
      this.state.rows,
      result.source.index,
      result.destination.index
    );

    this.setState({
      rows
    });

    // update backend
    this.props.onOrderChange(result);
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <table
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <thead>
                <tr>
                    {this.props.headers.map((header, index) => (
                        <th key={index} style={this.getRowHeaderStyle(index)}>{header}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {this.state.rows.map((item, index) => (
                  <Draggable key={item[0].toString()} draggableId={item[0].toString()} index={index}>
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.slice(1).map((col:any, index:number) => (
                            <td key={index} style={this.getRowWidthStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                              index
                            )}>{col}</td>
                        ))}
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            </table>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default DragAndDropTable;

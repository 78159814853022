import React, { Component } from 'react';
import Resizer from 'react-image-file-resizer';
import {Controlled as CodeMirror} from 'react-codemirror2'
import { Page, Card, TextField, FormLayout, Link, Button, Stack,
  Layout, Spinner, Select, TextStyle, Modal, Checkbox } from '@shopify/polaris';
import {Loading} from '@shopify/app-bridge-react';
import { getFetch, postFetch, putFetch, postUploadFetch } from '../helpers/request';
import { Redirect } from 'react-router';
import AutocompleteTags from './AutocompleteTags';
import 'codemirror/lib/codemirror';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/theme/monokai.css';
import 'codemirror/lib/codemirror.css';
import 'react-summernote/dist/react-summernote.css';
import ReactSummernote from 'react-summernote';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Tab.css';
import HeadingInfoModal from './HeadingInfoModal';

interface TabProps {
  type: string,
  id?: string,
  contentType?: string
}

interface TabState {
  hasLoaded: boolean,
  errorLoading: boolean,
  name: string,
  tabTitle: string,
  html: string,
  integrationHtml: string,
  isSaving: boolean,
  nameTouched: boolean,
  tabTitleTouched: boolean,
  redirectToListing: boolean,
  redirectToInstall: boolean,
  redirectId: string,
  selectedHeader: string,
  selectedTabType: string,
  selectedTargetType: string,
  showBanner:boolean,
  collectionOptions: Array<any>,
  selectedCollectionIds: Array<any>,
  theme: any,
  modalActive: boolean,
  displayAbove: boolean,
  displayFirstTabAbove: boolean
}

class Tab extends Component <TabProps, TabState> {
  summernoteRef:any;
  tabTypeOptions = [
    {label: 'Static', value: 'static'},
    {label: 'App Integration', value: 'app_integration'},
    {label: 'Product Description', value: 'product_description'}
  ];

  tabSettingsDescriptions:any = {
    product_description: 'Individual Tabs are used for content specific to a single product. Create multiple tabs in your Shopify admin Product Description by inserting Headings.',
    static: 'Common Tabs are used for content that applies to multiple products. Add and edit content that will be appear here.',
    app_integration: 'App Integration Tabs are used to insert 3rd party integration code. Copy and paste the app installation HTML/Liquid template code here.',
  }

  namePlaceholders:any = {
    product_description: 'e.g Individual Tabs, Description, Details',
    static: 'e.g  Material (Leather), Material(Wool)',
    app_integration: 'e.g  Product Reviews, Judge.me, Yotpo',
  }

  tabTitlePlaceholders:any = {
    product_description: 'e.g Description, Details, Features',
    static: 'e.g Material, Shipping, FAQs',
    app_integration: 'e.g  Reviews, Size Chart, Contact',
  }

  targetTypeOptions = [
    {label: 'All Products', value: 'all'},
    {label: 'Specific Collections', value: 'collections'},
  ]

  headerOptions = [
    {label: 'None', value: 'none'},
    {label: 'Heading 1', value: 'h1'},
    {label: 'Heading 2', value: 'h2'},
    {label: 'Heading 3', value: 'h3'},
    {label: 'Heading 4', value: 'h4'},
    {label: 'Heading 5', value: 'h5'},
    {label: 'Heading 6', value: 'h6'},
  ]

  constructor(props: TabProps) {
    super(props);
    this.state = { 
      modalActive: false,
      isSaving: false,
      hasLoaded: false,
      errorLoading: false,
      redirectToListing: false,
      redirectToInstall: false,
      redirectId: '',
      showBanner: true,
      name: '',
      nameTouched: false,
      tabTitle: '',
      tabTitleTouched: false,
      displayAbove: false,
      displayFirstTabAbove: false,
      html: '',
      integrationHtml: '',
      selectedTabType: 'static',
      selectedTargetType: 'all',
      selectedHeader: 'none',
      collectionOptions: [],
      selectedCollectionIds: [],
      theme: {
        showTitle: true,
        imagePadding: false
      }
    };
  }
  

  componentDidMount() {
    if (this.props.type === 'new') {
      this.fetchCollectionsData();
      if (this.props.contentType){
        this.setState({selectedTabType: this.props.contentType});
        if (this.props.contentType === 'product_description') {
          this.setState({name: 'Individual Tabs'});
        }
      }
    } else if (this.props.type === 'existing') {
      getFetch('/app/api/tabs/?id='+this.props.id, {})
        .then((res:any) => res.json()
        ).then(data => {
          this.setState({ name: data.name });
          this.setState({ tabTitle: data.tabTitle });
          this.setState({ integrationHtml: data.integrationHtml});
          this.setState({ selectedTargetType: data.target });
          this.setState({ selectedTabType: data.contentType });
          this.setState({ theme: data.theme });
          this.setState({ html: data.html });
          this.setState({ displayAbove: data.displayAbove });
          this.setState({ displayFirstTabAbove: data.displayFirstTabAbove });
          this.setState({ selectedHeader: data.header });
          this.setState({ selectedCollectionIds: data.collections.map((a:any) => a.id)});
          this.fetchCollectionsData();
        });
    }
  }

  toggleModalActive = () => {
    let active = this.state.modalActive;
    this.setState({modalActive: !active});
  }

  fetchCollectionsData() {
    getFetch('/app/api/collections', {})
      .then((collections_res:any) => collections_res.json()
      ).then(collectionsData => {
        let data = []
        for (let collectionData of collectionsData) {
          let datum = {
            value: collectionData.id,
            label: collectionData.title,
            type: collectionData.type
          }
          data.push(datum);
        }
        this.setState({collectionOptions: data})
        this.setState({ hasLoaded: true });
      });
  }

  save = () => {
    this.setState({isSaving:true});
    
    let collections = [];

    for (let selectedCollectionId of this.state.selectedCollectionIds) {
      collections.push(this.getCollectionObject(selectedCollectionId));
    }

    let html = this.state.html;
    if (this.state.selectedTabType==='static') {
      html = this.summernoteRef.editor.summernote('code');
    }
    let body:any = {
      name: this.state.name,
      tabTitle: this.state.tabTitle,
      html: html,
      theme: this.state.theme,
      contentType: this.state.selectedTabType,
      header: this.state.selectedHeader,
      target: this.state.selectedTargetType,
      collections: collections,
      integrationHtml: this.state.integrationHtml,
      displayAbove: this.state.displayAbove,
      displayFirstTabAbove: this.state.displayFirstTabAbove
    };
    
    if (this.props.type === 'new') {
      postFetch('/app/api/tabs/', body)
      .then((res:any) => res.json()).then(
        (data) => { 
          this.setState({redirectToListing: true});
        }
      ).catch( 
        (err) => console.log(err)
      );
    } else if (this.props.type === 'existing') {
      body.id = this.props.id;
      putFetch('/app/api/tabs/', body).then(
        (res) => { 
          this.setState({redirectToListing: true});
        }
      ).catch( 
        (err) => console.log(err)
      );
    }
    
  }

  handleNameChange = (value:string) => {
    this.setState({nameTouched: true});
    this.setState({name: value});
  };
  
  handleTabTitleChange = (value:string) => {
    this.setState({tabTitleTouched: true});
    this.setState({tabTitle: value});
  }

  handleImageUpload = (files:FileList) => {
    let formData = new FormData();
    let filesToUpload: Array<Blob> = [];
    if (files) {
      for (let i=0; i<files.length; i++) {
        Resizer.imageFileResizer(
          files[i],
          1500,
          1500,
          'PNG',
          100,
          0,
          (blob: any) => {
            filesToUpload.push(blob);
            if (i===files.length-1) {
              for (let f of filesToUpload) {
                formData.append('files', f);
              }
              if (this.props.id) {
                formData.append('tab', this.props.id + '');
              }
              postUploadFetch('/app/api/image/', formData)
                .then((res:any) => res.json()
                ).then(data => {
                  for (let url of data.urls) {
                    ReactSummernote.insertImage(url, (image:any)=>{
                      image.css({'max-width': '100%'})
                    });
                  }
                }
              )
            }
          },
          'blob'
        );
      }
    }
  }

  handleDisplayAboveChange = (value:boolean) => {
    this.setState({displayAbove: value});
  }

  handleDisplayFirstTabAboveChange = (value:boolean) => {
    this.setState({displayFirstTabAbove: value});
  }

  handleHeaderChange = (value:string) => {
    this.setState({selectedHeader: value});
  }

  handleTabTypeChange = (value:string) => {
    this.setState({selectedTabType: value});
  }

  handleTargetTypeChange = (value:string) => {
    this.setState({selectedTargetType: value});
  }

  redirectBackToListing = () => {
    this.setState({redirectToListing:true});
  }

  selectedCollectionChange = (selectedCollectionIds: Array<string>) => {
    this.setState({selectedCollectionIds: selectedCollectionIds});
  }

  getCollectionObject = (collectionId: Number) => {
    for (let collectionOption of this.state.collectionOptions) {
      if (collectionOption.value === collectionId) {
        return {
          id: collectionId,
          type: collectionOption.type
        }
      }
    }
  }
 
  initTags(data:any) {
    let availableTags = data.tags;
    availableTags.sort();
    this.setState({ hasLoaded: true });
  }

  onSummernoteChange = (content:any) => {
    this.setState({html: content});
  }

  render() {
    if (this.state.redirectToListing === true) {
      return <Redirect to='/tabs-listing' />
    } else if (this.state.redirectToInstall === true) {
      let url = '/install/' + this.state.redirectId;
      return (<Redirect to={url} />)
    }
    const TabTitleRegex = /^[a-zA-Z0-9-_.& ]+$/;
    let tabTypeMappings:any = {
      product_description: 'Individual Tabs',
      static: 'Common Tab',
      app_integration: 'App Integration Tab'
    }

    let contentTypeLabel = 'New Tab';
    if (this.props.contentType) {
      contentTypeLabel = tabTypeMappings[this.props.contentType];
    }


    return (
        <Page 
          title={this.props.type === 'new' ? "Create " + contentTypeLabel : "Edit Tab"}
          breadcrumbs={[{content: 'Product Tabs', onAction: this.redirectBackToListing}]}>
          {!this.state.hasLoaded && (<Loading />)}
          {!this.state.hasLoaded && (<div><Layout><Spinner size="large" /></Layout></div>)}
          {this.state.hasLoaded && (
                <Layout>
                  <Layout.AnnotatedSection
                    title="Name"
                    description="This is used to identify your tab for admin purposes only."
                  >
                    <Card sectioned>
                      <FormLayout>
                        <TextField
                          label="Enter Name"
                          value={this.state.name}
                          onChange={this.handleNameChange}
                          error={this.state.nameTouched && this.state.name.length==0 ? "Name is required" : 
                                //!TabTitleRegex.test(this.state.TabTitle) ? "Tab name contains illegal characters" :
                                false}
                          placeholder={this.namePlaceholders[this.state.selectedTabType]}
                        />
                      </FormLayout>
                    </Card>
                  </Layout.AnnotatedSection>
                  <Layout.AnnotatedSection
                    title="Tab Settings"
                    description={this.tabSettingsDescriptions[this.state.selectedTabType]}
                  >
                  <FormLayout>
                    {this.state.selectedTabType === 'product_description' &&
                    <Card sectioned>
                      <Checkbox
                        checked={this.state.displayAbove}
                        label="Display Product Description content above all tabs / accordions"
                        onChange={this.handleDisplayAboveChange}
                      />
                     <p><TextStyle variation="subdued">Enable this to make the Product Description content in your Shopify admin appear above all the tabs you have created</TextStyle></p>
                    </Card>
                    }
                    {(!this.state.displayAbove && this.state.selectedTabType === 'product_description' || this.state.selectedTabType !== 'product_description') && 
                    <Card sectioned>
                      <TextField
                          label="Enter Tab Title"
                          value={this.state.tabTitle}
                          onChange={this.handleTabTitleChange}
                          placeholder={this.tabTitlePlaceholders[this.state.selectedTabType]}
                          error={this.state.tabTitleTouched && this.state.tabTitle.length==0 ? "Tab Title is required" : 
                                //!TabTitleRegex.test(this.state.TabTitle) ? "Tab name contains illegal characters" :
                                false}
                          helpText={!this.state.displayAbove && this.state.selectedTabType === 'product_description' && this.state.selectedHeader !== 'none' ? 
                          'This will be displayed only when the Shopify admin Product Description does not start with a Heading configured to be a Tab Title' : ''}
                      />
                    </Card>}
                    {this.state.selectedTabType==='static' && (
                        <Card sectioned>
                          <TextStyle>Edit Content</TextStyle>
                          <ReactSummernote
                              ref={(el:any) => this.summernoteRef = el}
                              onImageUpload={this.handleImageUpload}
                              value={this.state.html}
                              options={{
                                height: 350,
                                toolbar: [
                                  ['style', ['style']],
                                  ['font', ['bold', 'underline', 'clear']],
                                  ['para', ['ul', 'ol', 'paragraph']],
                                  ['table', ['table']],
                                  ['insert', ['link', 'picture', 'video']],
                                  ['view', ['codeview']]
                                ],
                                styleTags: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
                                prettifyHtml: true,
                                codemirror: { 
                                  theme: 'monokai',
                                  mode : 'htmlmixed',
                                  lineNumbers: true,
                                  lineWrapping: true,
                                }
                              }}
                              onChange={this.onSummernoteChange}
                          />
                          <br />
                          <TextStyle variation="subdued">If you are uploading images larger than 1 Mb or GIF animations, you can upload it under Shopify admin Content &gt; Files and use the image URL instead.</TextStyle>
                        </Card>)
                    }
                    {this.state.selectedTabType==='product_description' && (
                        <div>
                          {!this.state.displayAbove && (
                            <Card sectioned>
                              <Select
                                label="Heading to use as Tab Title (in Shopify admin Product Description)"
                                options={this.headerOptions}
                                onChange={this.handleHeaderChange}
                                value={this.state.selectedHeader}
                                helpText="Selected Heading will be used to create a tab, with the Heading turning into a Tab Title. Content under this Heading will be content for the tab"
                              />
                              <Link onClick={this.toggleModalActive}>Learn more</Link>
                              {this.state.selectedHeader !== 'none' && (
                                <div>
                                  <br />
                                  <Checkbox
                                      checked={this.state.displayFirstTabAbove}
                                      label="Display first Heading tab content above all tabs / accordions"
                                      onChange={this.handleDisplayFirstTabAboveChange}
                                    />
                                   <p><TextStyle variation="subdued">Enable this to make the content above the first Heading tab or the first Heading tab created to appear above all tabs</TextStyle></p>
                                </div>
                              )}
                            </Card>
                          )}
                        </div>
                      )
                    }
                      {this.state.selectedTabType==='app_integration' && (
                        <div>
                          <p>Copy and paste the app installation HTML/Liquid template code here</p>
                          <CodeMirror
                            value={this.state.integrationHtml}
                            options={{
                              mode: 'htmlmixed',
                              theme: 'monokai',
                              lineNumbers: true,
                              lineWrapping:true
                            }}
                            onBeforeChange={(editor, data, value) => {
                              this.setState({integrationHtml: value});
                            }}
                            onChange={(editor, data, value) => {
                              this.setState({integrationHtml: value})
                            }}
                          />
                        </div>
                      )
                    }
                  </FormLayout>
                  </Layout.AnnotatedSection>
                  <Layout.AnnotatedSection
                    title="Target"
                    description="This tab will only appear for products in the collection/s you target. If you choose 'All', this tab will appear for every product."
                  >
                  <Card sectioned>
                  <FormLayout>
                    <Select
                      label="Target Type"
                      options={this.targetTypeOptions}
                      onChange={this.handleTargetTypeChange}
                      value={this.state.selectedTargetType}
                    />
                    {this.state.selectedTargetType === 'collections' && 
                    <AutocompleteTags helpText="Type to filter if it is hard to select / deselect" listTitle='Choose Collections' selected={this.state.selectedCollectionIds} onChange={this.selectedCollectionChange} label='Choose collections' options={this.state.collectionOptions} placeholderText='Search'></AutocompleteTags>}
                    
                  </FormLayout>
                  </Card>
                  </Layout.AnnotatedSection>
                  <Layout.Section>
                    <hr />
                    <Stack>
                      <Button onClick={this.redirectBackToListing}>Cancel</Button>
                      <Button primary loading={this.state.isSaving} disabled={this.state.name.length === 0 || this.state.tabTitle.length === 0 && (this.state.selectedTabType === 'app_integration' || this.state.selectedTabType === 'static') || this.state.tabTitle.length === 0 && !this.state.displayAbove && this.state.selectedTabType === 'product_description'} onClick={this.save}>{this.props.type==='new' ? 'Save Tab' : 'Update'}</Button>
                    </Stack>
                  </Layout.Section>
                  </Layout>
          )}
          <Modal
            open={this.state.modalActive}
            onClose={this.toggleModalActive}
            title="How to create Individual Tabs with Headings"
            primaryAction={{
              content: 'Done',
              onAction: this.toggleModalActive,
            }}
          >
          <HeadingInfoModal></HeadingInfoModal>
          </Modal>
        </Page>
    );
  }
}

export default Tab;

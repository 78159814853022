import React, { Component } from 'react';
import '@shopify/polaris/dist/styles.css';
import { AppProvider, Page, Card } from '@shopify/polaris';

interface PrivacyPageProps {

}

interface PrivacyPageState {

}

class PrivacyPage extends Component <PrivacyPageProps, PrivacyPageState> {

  constructor(props: PrivacyPageProps) {
    super(props);
  }

  componentDidMount() {

  }
  
  render() {
    return (
      <AppProvider i18n={{}}>
        <Page title="Custom Product Accordion Tabs Privacy Policy">
            <Card sectioned>
              <Card.Section>
                <p>Custom Product Accordion Tabs "the App” provides a mechanism to manage tabs in order to organise information on individual product pages "the Service" to merchants who use Shopify to power their stores. This Privacy Policy describes how personal information is collected, used, and shared when you install or use the App in connection with your Shopify-supported store.</p>
              </Card.Section>
              <Card.Section title="Personal Information the App Collects">
                <p>When you install the App, we are automatically able to access certain types of information from your Shopify account: Products, Themes, Script Tags and Content.</p>
                <p><br />Additionally, we collect the following types of personal information from you once you have installed the App: Information about you and others who may access the App on behalf of your store, such as your name, address, email address, phone number, and billing information;</p>
              </Card.Section>
              <Card.Section title="How Do We Use Your Personal Information?">
                <p>We use the personal information we collect from you and your customers in order to provide the Service and to operate the App. Additionally, we use this personal information to: Communicate with you; Optimize or improve the App; and Provide you with information or advertising relating to our products or services.</p>
              </Card.Section>
              <Card.Section title="Sharing Your Personal Information">
                <p>We may share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>
              </Card.Section>
              <Card.Section title="Your Rights If you are a European Resident">
                <p>If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.</p>
                <p><br />Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.</p>
              </Card.Section>
              <Card.Section title="Data Retention">
                When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.
              </Card.Section>
              <Card.Section title="Changes">
                We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
              </Card.Section>
              <Card.Section title="Contact Us">
                For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at hello@voidworks.co
              </Card.Section>
              
            </Card>
        </Page>
      </AppProvider>
    );
  }
}

export default PrivacyPage;
import React, { Component } from 'react';
import { Link, FooterHelp } from '@shopify/polaris';

class FooterLink extends Component {
    render() {
        return (
            <FooterHelp>
            Learn more about {" "}
            <Link external url="https://apps.voidworks.co/custom-product-accordion-tabs/getting-started">
            how to use the app
            </Link>
          </FooterHelp>
        );
    }
}

export default FooterLink;
import React, { Component } from 'react';
import {Provider} from '@shopify/app-bridge-react';
import {AppProvider} from '@shopify/polaris';
import Tab from '../components/Tab';
import { getFetch } from '../helpers/request';

interface NewTabPageProps {
  contentType: string,
  match: any
}

interface NewTabPageState {
  shopOrigin: string,
  apiKey: string
}

class NewTabPage extends Component <NewTabPageProps, NewTabPageState> {

  constructor(props: NewTabPageProps) {
    super(props);
    this.state = { 
      shopOrigin: '',
      apiKey: ''
    };
  }

  componentDidMount() {
    //check if log in
    getFetch('/app/api/shopify/user', {})
    .then((res: any) => res.json()
    ).then(data => { 
      this.setState({ shopOrigin: data.shop_origin, apiKey: data.api_key })
    }).catch(err => {

    });
  }
  
  render() {
    const config = {apiKey: this.state.apiKey, shopOrigin: this.state.shopOrigin, host:Buffer.from('admin.shopify.com/store/' + this.state.shopOrigin.split('.')[0]).toString('base64')};    return (
      this.state.shopOrigin !== '' && this.state.apiKey !== '' &&
      <AppProvider i18n={{}}>
        <Provider config={config}>
          <Tab type="new" contentType={this.props.match.params.contentType}></Tab>
        </Provider>
      </AppProvider>
    );
  }
}

export default NewTabPage;

import React, { Component } from 'react';
import { Modal } from '@shopify/polaris';

import screen0 from '../images/screen0.jpg';
import screen1 from '../images/screen1.jpg';
import screen2 from '../images/screen2.jpg';
import screen3 from '../images/screen3.jpg';
import screen4 from '../images/screen4.jpg';

interface Props {

}

class HeadingInfoModal extends Component {

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
    }

    render() {
        return (
            <Modal.Section>

                <p>You can create tabs within your Shopify admin Product Description using Headings. This is useful if you want to add tabs to a single product only e.g. allergen information specific to one product.</p>
                <div style={{position:'relative', width:'100%',paddingBottom:'56.25%'}}>
                    <iframe style={{position:'absolute', top:0, left:0}} width="100%" height="100%" src="https://www.youtube.com/embed/l2U1jFg0blg" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                </div>
                <hr/>
                <p>1. From the app, either <strong>Create or Edit Individual Tabs.</strong></p>
                <p>2. Under <strong>Tab Settings</strong>, select the Heading to use as Tab Title.</p>
                <img className="full-width" src={screen0} />
                <p>3. From your Shopify admin, go to <strong>Products &gt; All products</strong></p>
                <p>4. Click the name of the product that you want to add Individual Tabs to.</p>
                <p>5. Under <strong>Description</strong>, add and/or highlight the text you want to use as Tab Title and format it to the Heading you have selected at Step 2.</p>
                <img className="full-width" src={screen1} />
                <p>6. Content under each formatted Heading will become content in their respective tabs.</p>
                <img className="full-width" src={screen2} />
                <p>7. Click <strong>Save</strong>.</p>
                <hr/>
                <p>The Individual Tabs you have just created will be reflected in your store as long as you have enabled them at the '<strong>Product Tabs</strong>'' dashboard and turned on publish at <strong>'Style/Publish Product Tabs'</strong>.</p>
                <img className="full-width" src={screen3} />
                <img className="full-width" src={screen4} />
            </Modal.Section>
        )
    }
}

export default HeadingInfoModal;
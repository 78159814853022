import React, { Component, useRef } from 'react';
import Toggle from 'react-toggle';
import ThemeEditor from './ThemeEditor';
import "react-toggle/style.css";
import Support from './Support';
import { getFetch, deleteFetch, patchFetch, postFetch, putFetch } from '../helpers/request';
import TabPreview from './TabPreview';
import DragAndDropTable from './draggable-table/DragAndDropTable';
import './TabListing.css';
import { Card, Button, Link, Banner, Tabs, DisplayText, FormLayout, Subheading, Heading, Stack, Spinner, Modal, Layout, Icon, TextStyle, List, DataTable, FooterHelp } from '@shopify/polaris';
import { Loading } from '@shopify/app-bridge-react';
import PropTypes from "prop-types";
import pageTabOneImage from '../images/pagetab-1.svg';
import pageTabTwoImage from '../images/pagetab-2.svg';
import tabOneImage from '../images/tab-1.svg';
import tabTwoImage from '../images/tab-2.svg';
import tabThreeImage from '../images/tab-3.svg';
import HeadingInfoModal from "./HeadingInfoModal";
import FooterLink from "./FooterLink";
import screen2a from '../images/screen2-0.jpg';
import screen2b from '../images/screen2-1.jpg';
import screen2c from '../images/screen2-2.jpg';
import screen2d from '../images/screen2-3.jpg';
import screen2e from '../images/screen2-4.jpg';

type Dictionary = { [index: string]: any }

interface TabListingProps {
  selectedTabIndex?: number
}

interface TabListingState {
  shouldOpenEmbedAppIntegrationModal: boolean,
  themeId: string,
  themeSettingsURL: string,
  themeSupportAppExtensions: boolean,
  tabs: Array<any>,
  pageTabs: Array<any>,
  theme: Dictionary,
  hasLoaded: boolean,
  errorLoading: boolean,
  isRefreshing: boolean,
  showBanner: boolean,
  shouldOpenDeleteModal: boolean,
  currentEmbedIndex: number,
  deleteIndex: number,
  deleteComponentIndex: number,
  deleteLoading: boolean,
  deleteComponentLoading: boolean,
  selectedTabIndex: number,
  orderChangedKey: string,
  isSavingTheme: boolean,
  hasThemeChanged: boolean,
  isPublished: boolean,
  modalActive: boolean,
  publishLoading: boolean,
  shouldOpenDeleteComponentModal: boolean,
  shouldOpenEmbedModal: boolean,
  copyToClipboardText: string,
  isPageTabsPublished: boolean,
  publishPageTabsLoading: boolean,
  //theme
  style: string,
  desktopType: string,
  mobileType: string,
  defaultColor: any,
  defaultBgColor: any,
  defaultFontWeight: string,
  activeColor: any,
  activeBgColor: any,
  activeFontWeight: string,
  strokeColor: any,
  bgColor: any,
  textColor: any,
  iconType: string,
  iconDefaultColor: any,
  iconActiveColor: any,
  accordionDefaultCollapsed: boolean,
  accordionAllowMultipleExpand: boolean,
  accordionDefaultExpandAll: boolean,
  shouldOpenEmbedHelpModal: boolean,
  shouldOpenProductTabsEmbedCodeModal: boolean
  shouldOpenHTMLSnippetCodeModal: boolean
}

class TabListing extends Component <TabListingProps, TabListingState> {
  
  static contextTypes = { polaris: PropTypes.object, };
  previewRef:any;

  defaultTheme: any = {
    style: 'normal',
    desktopType: 'tabs',
    mobileType: 'accordion',
    iconType: 'plus',
    iconDefaultColor: {r:150,g:150,b:150,a:1},
    iconActiveColor: {r:103,g:103,b:103,a:1},
    defaultColor: {r:150,g:150,b:150,a:1},
    defaultBgColor: {r:236,g:236,b:236,a:1},
    defaultFontWeight: 'normal',
    activeColor: {r:103,g:103,b:103,a:1},
    activeBgColor: {r:255,g:255,b:255,a:1},
    activeFontWeight: 'bold',
    strokeColor: {r:230,g:230,b:230,a:1},
    bgColor: {r:255,g:255,b:255,a:1},
    textColor: {r:43,g:43,b:43,a:1},
    accordionDefaultCollapsed: false,
    accordionAllowMultipleExpand: false,
    accordionDefaultExpandAll: false,
  };

  constructor(props: TabListingProps) {
    super(props);
    this.state = { 
      shouldOpenEmbedAppIntegrationModal: false,
      themeId: '',
      themeSettingsURL: '',
      themeSupportAppExtensions: false,
      tabs: [],
      pageTabs: [],
      theme: {},
      shouldOpenDeleteComponentModal: false,
      publishLoading: false,
      hasLoaded: false,
      errorLoading: false,
      isRefreshing: false,
      showBanner: true,
      shouldOpenDeleteModal: false,
      currentEmbedIndex: 0,
      deleteIndex: 0,
      deleteComponentIndex: 0,
      deleteLoading: false,
      deleteComponentLoading: false,
      selectedTabIndex: this.props.selectedTabIndex === undefined ? 0 : this.props.selectedTabIndex!,
      orderChangedKey: '' + new Date().valueOf(),
      isSavingTheme: false,
      hasThemeChanged: false,
      isPublished: false,
      isPageTabsPublished: false,
      modalActive: false,
      shouldOpenEmbedModal: false,
      copyToClipboardText: 'Copy to Clipboard',
      shouldOpenEmbedHelpModal: false,
      publishPageTabsLoading: false,
      //theme
      style: '',
      desktopType: '',
      mobileType: '',
      defaultColor: {},
      defaultBgColor: {},
      defaultFontWeight: '',
      activeColor: {},
      activeBgColor: {},
      activeFontWeight: '',
      strokeColor: {},
      bgColor: {},
      textColor: {},
      iconDefaultColor: {},
      iconActiveColor: {},
      iconType: '',
      accordionDefaultCollapsed: false,
      accordionAllowMultipleExpand: false,
      accordionDefaultExpandAll: false,
      shouldOpenProductTabsEmbedCodeModal: false,
      shouldOpenHTMLSnippetCodeModal: false
    };
    this.previewRef = React.createRef();
  }

  componentDidMount() {
    getFetch('/app/api/tabs', {})
    .then((res: any) => res.json()
    ).then(data => { 
      this.setState({themeSupportAppExtensions: data.theme_supports_app_extensions})
      this.setState({themeId: data.theme_id});
      this.setState({themeSettingsURL: 'https://' + data.shopify_url +'/admin/themes/' + data.theme_id + '/editor?context=apps'});
      this.setState({ tabs: data.tabs });
      getFetch('/app/api/components', {})
      .then((res: any) => res.json()
      ).then(dataTwo => {
        this.setState({ pageTabs: dataTwo})
        getFetch('/app/api/theme', {}).then((res: any) => {
          if (res.ok) {
            return res.json();
          } else if (res.status === 404) {
            this.saveTheme(this.defaultTheme);
            for (let key of Object.keys(this.defaultTheme)) {
              this.setState<never>({[key]: this.defaultTheme[key]});
            }
            this.setState({ hasLoaded: true });
            return null;
          }
        }).then(data => {
          if (data === null) {
            return;
          }
          if (window.location.search === '?tabindex=2') {
            this.setState({selectedTabIndex: 2});
          }
          this.setState({isPublished: data.published})
          this.setState({isPageTabsPublished: data.page_tab_published})
          let theme = JSON.parse(data.theme);
          let toUpdateDefault = false;
          for (let key of Object.keys(this.defaultTheme)) {
            if (!(key in theme)) {
              theme[key] = this.defaultTheme[key];
              toUpdateDefault = true;
            }
          }
          for (let key of Object.keys(theme)) {
            this.setState<never>({[key]: theme[key]});
          }
          this.setState({ theme: theme });
          if (toUpdateDefault) {
            this.saveTheme(theme);
          } else {
            this.setState({ hasLoaded: true });
          }
        })
      }).catch(err => {
        this.setState({ hasLoaded: true });
        this.setState({ errorLoading: true });
      });
    });
  }

  componentWillUnmount() {

  }

  toggleModalActive = () => {
    let active = this.state.modalActive;
    this.setState({modalActive: !active});
  }

  saveTheme(theme?: any) {
    this.setState({isSavingTheme: true});
    if (theme === undefined) {
      theme = this.state.theme;
    } else {
      this.setState({theme: theme});
    }
    postFetch('/app/api/theme/',
      {theme: JSON.stringify(theme)}
    ).then((res:any) => {
      if (res.ok) {
        this.setState({ hasLoaded: true });
        this.setState({hasThemeChanged: false});
      }
      this.setState({isSavingTheme: false});
    });
  }

  openHTMLSnippetCodeModal = () => {
    this.setState({copyToClipboardText: 'Copy to Clipboard'});
    this.setState({shouldOpenHTMLSnippetCodeModal:true});
  }

  onCloseHTMLSnippetCodeModal = () => {
    this.setState({shouldOpenHTMLSnippetCodeModal:false});
  }

  openProductTabsEmbedCodeModal = () => {
    this.setState({shouldOpenProductTabsEmbedCodeModal:true});
  }

  onCloseProductTabsEmbedCodeModal = () => {
    this.setState({shouldOpenProductTabsEmbedCodeModal:false});
  }

  openDeleteModal = (index:number) => {
    this.setState({deleteIndex: index});
    this.setState({shouldOpenDeleteModal:true});
  }

  openDeleteComponentModal = (index:number) => {
    this.setState({deleteComponentIndex: index});
    this.setState({shouldOpenDeleteComponentModal:true});
  }  

  onCloseEmbedModal = () => {
    this.setState({shouldOpenEmbedModal:false});
  }

  onOpenEmbedModal = (index:number) => {
    this.setState({currentEmbedIndex: index});
    this.setState({copyToClipboardText: 'Copy to Clipboard'});
    this.setState({shouldOpenEmbedModal: true});
  }
 
  closeDeleteModal = () => {
    this.setState({shouldOpenDeleteModal:false});
  }

  onCloseDeleteModal = () => {
    this.closeDeleteModal();
  }

  onCloseDeleteComponentModal = () => {
    this.setState({shouldOpenDeleteComponentModal: false});
  }

  copyEmbedCode = () => {
    let text = "<div data-id='" + this.state.pageTabs[this.state.currentEmbedIndex].uuid + "' class='vw-page-tabs id" + this.state.pageTabs[this.state.currentEmbedIndex].uuid + "' data-name='" + this.state.pageTabs[this.state.currentEmbedIndex].name + "'>Loading...</div>";
    navigator.clipboard.writeText(text).then(()=> {
      this.setState({copyToClipboardText: 'Copied!'});
    });
  }

  confirmDelete = () => {
    let deleteIndex = this.state.deleteIndex;
    this.setState({deleteLoading: true})
    let deletingId = this.state.tabs[deleteIndex].id;
    let resultingTabs:any[] = [];
    for (let tab of this.state.tabs) {
      if (tab.id !== deletingId){
        resultingTabs.push(tab);
      }
    }
    deleteFetch('/app/api/tabs/', { id: deletingId })
    .then((res) => {
      this.closeDeleteModal();
      this.setState({deleteLoading: false});
      this.setState({tabs: resultingTabs});
    }).catch(err => {
      this.setState({deleteLoading: false});
    });
  }

  confirmDeleteComponent = () => {
    let deleteComponentIndex = this.state.deleteComponentIndex;
    this.setState({deleteComponentLoading: true})
    let deletingId = this.state.pageTabs[deleteComponentIndex].id;
    let resultingPageTabs:any[] = [];
    for (let tab of this.state.pageTabs) {
      if (tab.id !== deletingId){
        resultingPageTabs.push(tab);
      }
    }
    deleteFetch('/app/api/components/', { id: deletingId })
    .then((res) => {
      this.onCloseDeleteComponentModal();
      this.setState({deleteComponentLoading: false});
      this.setState({pageTabs: resultingPageTabs});
    }).catch(err => {
      this.setState({deleteComponentLoading: false});
    });
  }

  reorder = (list:Array<any>, startIndex:number, endIndex:number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  handleOrderChange = (result: any) => {
    let tabs = this.reorder(
      this.state.tabs,
      result.source.index,
      result.destination.index
    );
    this.setState({tabs: tabs});
    let data:Array<any> = [];
    tabs.map((tab, index)=> {
      let datum = {order: index, id: tab.id};
      data.push(datum);
    })
    postFetch('/app/api/reorder/', { tabs: data });
  }

  handlePublishToggle = (event:any) => {
    let isPublished = event.target.checked;
    this.setState({isPublished});
    this.setState({publishLoading: true});
    patchFetch('/app/api/theme/',
      {published: isPublished}
    ).then(res => {
      this.setState({publishLoading: false});
    });
  }

  handlePublishPageTabsToggle = (event:any) => {
    let isPageTabsPublished = event.target.checked;
    this.setState({isPageTabsPublished});
    this.setState({publishPageTabsLoading: true});
    putFetch('/app/api/theme/',
      {page_tabs_published: isPageTabsPublished}
    ).then(res => {
      this.setState({publishPageTabsLoading: false});
    });
  }

  handleTabToggle = (event:any, index:number, id:number) => {
    const tabs = this.state.tabs.map((item, j) => {
      if (j === index) {
        item.enabled = event.target.checked;
        item.disableToggle = true;
        return item;
      } else {
        return item;
      }
    });
    this.setState({tabs});
    patchFetch('/app/api/tabs/', { id: id, enabled:event.target.checked }).then((res) => {
      const tabs = this.state.tabs.map((item, j) => {
        if (j === index) {
          item.disableToggle = false;
          return item;
        } else {
          return item;
        }
      });
      this.setState({tabs});
    });
  }

  getHTMLSnippetCode = () => {
    let HTMLSnippetEmbedCode = '<div class="vw-product-description"></div>';
    return HTMLSnippetEmbedCode;
  }

  copyHTMLSnippetCode = () => {
    let text = this.getHTMLSnippetCode();
    navigator.clipboard.writeText(text).then(()=> {
      this.setState({copyToClipboardText: 'Copied!'});
    });
  }

  getProductTabsEmbedCode = () => {
    let productTabsEmbedCode = `
      <div id="vw-custom-product-tag-container">
      <script id="vw-custom-product-tag" type="application/json">{{ shop.metafields.vw_cpat.pd_settings }}</script>
      </div>
      
      {% capture collection_ids %}
      {% for collection in product.collections %}{{ collection.id }},{% endfor %}
      {% endcapture %}
      
      <div id="vw-custom-product-collections-tag-container">
      <script id="vw-custom-product-collections-tag" type="application/json">{"collection_ids": "{{ collection_ids | strip }}", "product_description": "{{ product.description | strip_newlines | escape }}" }</script>
      </div>
      
      {% if shop.metafields.vw_cpat.pd_1 %}
      <div id="shopify-section-vw-product-tabs-1">
      <script id="vw-custom-product-tabs-1" type="application/json">{{ shop.metafields.vw_cpat.pd_1 }}</script>
      </div>
      {% endif %}
      
      {% if shop.metafields.vw_cpat.pd_2 %}
      <div id="shopify-section-vw-product-tabs-2">
      <script id="vw-custom-product-tabs-2" type="application/json">{{ shop.metafields.vw_cpat.pd_2 }}</script>
      </div>
      {% endif %}
      
      {% if shop.metafields.vw_cpat.pd_3 %}
      <div id="shopify-section-vw-product-tabs-3">
      <script id="vw-custom-product-tabs-3" type="application/json">{{ shop.metafields.vw_cpat.pd_3 }}</script>
      </div>
      {% endif %}
      
      {% if shop.metafields.vw_cpat.pd_4 %}
      <div id="shopify-section-vw-product-tabs-4">
      <script id="vw-custom-product-tabs-4" type="application/json">{{ shop.metafields.vw_cpat.pd_4 }}</script>
      </div>
      {% endif %}
      
      {% if shop.metafields.vw_cpat.pd_5 %}
      <div id="shopify-section-vw-product-tabs-5">
      <script id="vw-custom-product-tabs-5" type="application/json">{{ shop.metafields.vw_cpat.pd_5 }}</script>
      </div>
      {% endif %}
      
      {% if shop.metafields.vw_cpat.pd_6 %}
      <div id="shopify-section-vw-product-tabs-6">
      <script id="vw-custom-product-tabs-6" type="application/json">{{ shop.metafields.vw_cpat.pd_6 }}</script>
      </div>

      {% endif %}

      <script async src="{{ 'vw_custom_product_tabs_pd_1.js' | file_url }}"></script>
      <script async src="{{ 'vw_custom_product_tabs_pd_2.js' | file_url }}"></script>
      <script async src="{{ 'vw_custom_product_tabs_pd_3.js' | file_url }}"></script>
      <script async src="{{ 'vw_custom_product_tabs_pd_4.js' | file_url }}"></script>
    `;
    productTabsEmbedCode += this.getAppIntegrationEmbedCode();
    productTabsEmbedCode = productTabsEmbedCode.replace(/(\r\n|\n|\r|\t)/gm, "");
    productTabsEmbedCode = '<!-- START Custom Accordion Product Tabs Embed Code -->\n' + productTabsEmbedCode + '\n<!-- END Custom Accordion Product Tabs Embed Code -->';
    return productTabsEmbedCode;
  }

  copyProductTabsEmbedCode = () => {
    let text = this.getProductTabsEmbedCode();
    navigator.clipboard.writeText(text).then(()=> {
      this.setState({copyToClipboardText: 'Copied!'});
    });
  }

  getAppIntegrationEmbedCode = () => {
    let appIntegrationEmbedCode = '';
    this.state.tabs.forEach((tab, index) => {
      if (tab.contentType === 'app_integration') {
        appIntegrationEmbedCode += '<div class="vw-app-integration-tab" style="display:none;" id="vw-app-integration-tab-' + tab.id + '">';
        appIntegrationEmbedCode += tab.integrationHtml;
        appIntegrationEmbedCode += "</div>"
      }
    });
    return appIntegrationEmbedCode;
  }

  copyAppIntegrationEmbedCode = () => {
    let text = this.getAppIntegrationEmbedCode();
    navigator.clipboard.writeText(text).then(()=> {
      this.setState({copyToClipboardText: 'Copied!'});
    });
  }

  openAppIntegrationEmbedCodeModal = () => {
    this.setState({copyToClipboardText: 'Copy to Clipboard'});
    this.setState({shouldOpenEmbedAppIntegrationModal: true});
  }

  onCloseEmbedAppIntegrationModal = () => {
    this.setState({shouldOpenEmbedAppIntegrationModal: false});
  }

  openEmbedHelpModal = () => {
    this.setState({copyToClipboardText: 'Copy to Clipboard'});
    this.setState({shouldOpenEmbedHelpModal: true});
  }

  onCloseEmbedHelpModal = () => {
    this.setState({shouldOpenEmbedHelpModal: false});
  }
  

  handleTabChange = (selected:number) => {
    this.setState({selectedTabIndex: selected});
  }

  handleThemeChange = (selected:any, id:string) => {
    this.setState({hasThemeChanged: true});
    this.setState<never>({[id]: selected});
    let theme = Object.assign({}, this.state.theme);
    theme[id] = selected;
    if (id === 'style') {
      if (selected === 'normal') {
        theme.defaultColor = {r:150, g:150, b:150, a:1};
        theme.activeColor = {r:103, g:103, b:103, a:1};
        theme.defaultBgColor = {r:236, g:236, b:236, a:1};
        theme.activeBgColor = {r:255, g:255, b:255, a:1};
        theme.textColor = {r:43, g:43, b:43, a:1};
        theme.strokeColor = {r:230, g:230, b:230, a:1};
        theme.bgColor = {r:255, g:255, b:255, a:1};
        theme.iconActiveColor = {r:103,g:103,b:103,a:1};
        theme.iconDefaultColor = {r:150,g:150,b:150,a:1};
      } else if (selected === 'underline') {
        theme.defaultColor = {r:43, g:43, b:43, a: 1};
        theme.activeColor = {r:43, g:43, b:43, a: 1};
        theme.defaultBgColor = {r:236, g:236, b:236, a:0};
        theme.activeBgColor = {r:255, g:255, b:255, a:0};
        theme.textColor = {r:0, g:0, b:0, a:1};
        theme.bgColor = {r:255, g:255, b:255, a:0};
        theme.strokeColor = {r:230, g:230, b:230, a:0};
        theme.iconActiveColor = {r:43, g:43, b:43, a: 1};
        theme.iconDefaultColor = {r:150,g:150,b:150,a:1};
      } else if (selected === 'link') {
        theme.defaultColor = {r:121, g:121, b:121, a:1};
        theme.defaultBgColor = {r:236, g:236, b:236, a:0};
        theme.activeColor = {r:43, g:43, b:43, a: 1};
        theme.activeBgColor = {r:255, g:255, b:255, a:0};
        theme.bgColor = {r:255, g:255, b:255, a:0};
        theme.strokeColor = {r:230, g:230, b:230, a:0};
        theme.textColor = {r:43, g:43, b:43, a:1};
        theme.iconActiveColor = {r:103,g:103,b:103,a:1};
        theme.iconDefaultColor = {r:150,g:150,b:150,a:1};
      } else if (selected === 'box') {
        theme.defaultColor = {r:43, g:43, b:43, a: 1};
        theme.defaultBgColor = {r:239, g:239, b:239, a: 1};
        theme.activeColor = {r:43, g:43, b:43, a: 1};
        theme.activeBgColor = {r:249, g:249, b:249, a: 1};
        theme.bgColor = {r:249, g:249, b:249, a: 1};
        theme.strokeColor = {r:230, g:230, b:230, a:0};
        theme.textColor = {r:43, g:43, b:43, a:1};
        theme.iconActiveColor = {r:103,g:103,b:103,a:1};
        theme.iconDefaultColor = {r:150,g:150,b:150,a:1};
      } else if (selected === 'pill') {
        theme.defaultColor = {r:43, g:43, b:43, a: 1};
        theme.defaultBgColor = {r:239, g:239, b:239, a: 1};
        theme.activeColor = {r:255, g:255, b:255, a: 1};
        theme.activeBgColor = {r:43, g:43, b:43, a: 1};
        theme.bgColor = {r:236, g:236, b:236, a:0};;
        theme.strokeColor = {r:230, g:230, b:230, a:0};
        theme.textColor = {r:43, g:43, b:43, a:1};
        theme.iconActiveColor = {r:255, g:255, b:255, a:1};
        theme.iconDefaultColor = {r:43,g:43,b:43,a:1};
      }
      this.setState({iconActiveColor: theme.iconActiveColor});
      this.setState({iconDefaultColor: theme.iconDefaultColor});
      this.setState({defaultColor: theme.defaultColor});
      this.setState({defaultBgColor: theme.defaultBgColor});
      this.setState({activeColor: theme.activeColor});
      this.setState({activeBgColor: theme.activeBgColor});
      this.setState({textColor: theme.textColor});
      this.setState({bgColor: theme.bgColor});
      this.setState({strokeColor: theme.strokeColor});
    }
    this.setState({theme});
    if (id === 'desktopType' || id === 'mobileType' || id === 'accordionDefaultCollapsed' || id === 'accordionAllowMultipleExpand' || id === 'accordionDefaultExpandAll') {
      if (this.previewRef.current) {
        this.previewRef.current.reset(theme);
      }
    } else {
      if (this.previewRef.current) {
        this.previewRef.current.resetStyles(theme);
      }
    }
  };

  render() {
    const mainTabs = [
      { id: 'tabs-listing', content: 'Product Tabs', panelID: 'tabs-panel'},
      { id: 'style', content: 'Style/Publish Product Tabs', panelID: 'style-panel'},
      { id: 'page-tabs-listing', content: 'Page Tabs', panelID: 'page-tabs-panel'},
      { id: 'support', content: 'FAQs and Support', panelID: 'support-panel'},
    ];

    let pageTabRows: any[] = [];
    this.state.pageTabs.forEach((tab, index) => {
      let row = [
        <Link url={'/page-components/' + tab.id}><TextStyle key={tab.id}>{tab.name}</TextStyle></Link>,
        <Stack>
        <Button key={tab.id} size="slim" onClick={()=>this.onOpenEmbedModal(index)}>Embed</Button>
        <Button key={tab.id} size="slim" onClick={()=>this.openDeleteComponentModal(index)} destructive>Delete</Button>
        </Stack>
      ]
      pageTabRows.push(row);
    });

    let rows: any[] = [];
    let toBeDeletedTitle = this.state.tabs[this.state.deleteIndex] ? this.state.tabs[this.state.deleteIndex].name : '';
   
    let toBeDeletedComponentName = this.state.pageTabs[this.state.deleteComponentIndex] ? this.state.pageTabs[this.state.deleteComponentIndex].name : '';


    let tabTypeMappings:any = {
      product_description: 'Individual Tabs',
      static: 'Common Tab',
      app_integration: 'App Integration Tab'
    }

    let appIntegrationEmbedCode = this.getAppIntegrationEmbedCode();
    let productTabsEmbedCode = this.getProductTabsEmbedCode();
    let HTMLSnippetCode = this.getHTMLSnippetCode();
    let hasAppIntegrationTabs = false;

    this.state.tabs.forEach((tab, index) => {
      if (tab.contentType === 'app_integration') {
        hasAppIntegrationTabs = true;
      }
      let row = [
        tab.id,
        <Icon key={tab.id} source='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="%23919eab" d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"/></svg>' />,
        <Link key={tab.id} url={"/product-tabs/"+tab.id}>{tab.name}</Link>,
        <TextStyle key={tab.id}>{tab.tabTitle}</TextStyle>,
        <span className="title-case"><TextStyle key={tab.id}>{tabTypeMappings[tab.contentType]}</TextStyle></span>,
        <TextStyle key={tab.id}><span className="title-case">{tab.target}</span>{tab.target==='collections' && ': ' + tab.collections.map((c:any) => c.title).join(', ')}</TextStyle>,
        <Toggle key={tab.id} icons={false} disabled={tab.disableToggle} onChange={(event)=> {this.handleTabToggle(event, index, tab.id)}} defaultChecked={tab.enabled}></Toggle>,
        <Button key={tab.id} destructive size="slim" onClick={() => this.openDeleteModal(index)}>Delete</Button>
      ]
      rows.push(row);
    });

    let editIndividualTabUrl = '/new/product_description';
    let productDescriptionExists = false;
    for (let t of this.state.tabs) {
      if (t.contentType === 'product_description') {
        editIndividualTabUrl = '/product-tabs/' + t.id;
        productDescriptionExists = true;
        break;
      }
    }

    let shouldShowPreview = false;
    for (let t of this.state.tabs) {
      if (t.enabled) {
        shouldShowPreview = true;
        break;
      }
    }

    return (
      <React.Fragment>
        {!this.state.hasLoaded && (<Loading />)}
        {!this.state.hasLoaded && (
          <div className="loader">
            <Layout><Spinner size="large" /></Layout>
            {/*<Layout><DisplayText size="small"><br />Loading... This might take a few minutes if you have many collections and products.<br />Thank you for your patience.</DisplayText></Layout>*/}
          </div>
          )}
        {this.state.hasLoaded && this.state.errorLoading &&
        (
          <Banner
            title="Error loading account"
            status="critical"
          >
            <p>Please ensure that you are logged in on Shopify.</p>
          </Banner>
        )}
        {this.state.hasLoaded && (
          <React.Fragment>
          <Tabs tabs={mainTabs} selected={this.state.selectedTabIndex} onSelect={this.handleTabChange}></Tabs>
          
          {this.state.selectedTabIndex === 0 && (
            <div className="tab-zero">
                <Banner
                  title="Get Started"
                  status="info"
                >
                  Create and manage your Product Tabs here. Once you are ready, head to '<Link onClick={() => this.handleTabChange(1)}>Style/Publish Product Tabs</Link>'.
                </Banner>
                {this.state.tabs.length > 0 &&
                <div>
                  <DisplayText size="large">Product Tabs</DisplayText>
                  <p className="mt-4">Drag and drop to reorder the sequence in which your tabs appear on your product page. Edit tabs by clicking on its name.</p>
                  {this.state.isRefreshing && (<div className="main-spinner"><Spinner size="small" /></div>)}
                  <Card>
                      <DragAndDropTable
                          onOrderChange={this.handleOrderChange}
                          headers={['', 'Name', 'Tab Title', 'Type', 'Target', 'Enabled', 'Actions']}
                          percentageWidths = {[5,20,20,15,20,10,10]}
                          rows={rows}
                      />
                      {this.state.tabs.length === 0 && (
                        <p className="text-center mt-4 mb-4"><TextStyle variation="subdued">You have no product tabs yet. Create the relevant tabs you need for your shop below.</TextStyle></p>
                      )}
                  </Card>
                  <br></br>
                  <hr />
                </div> }
                <p className="text-center">
                <TextStyle variation="subdued">There are 3 types of Product Tabs. Create the tabs you need for your shop. You can apply more than 1 tab type to a product or collection.</TextStyle>
                </p>
                <Layout>
                  <Layout.Section secondary>
                    <Card sectioned>
                      <div className="text-center">
                        <img className="block" src={tabOneImage} />
                        <p className="mt-4"><TextStyle variation="strong">Individual Tabs are used for content specific to a single product</TextStyle></p>
                        <p><TextStyle>Create multiple tabs in your Shopify admin Product Description by using Headings</TextStyle></p>
                        <p><Link onClick={this.toggleModalActive}>Learn more</Link></p>
                        <p className="mb-4"><TextStyle variation="subdued">Examples: 
                        <br />
                        Description, Features, Dimension, Ingredients, Allergens, Awards</TextStyle></p>
                        {productDescriptionExists && <Button url={editIndividualTabUrl}>Edit Individual Tabs</Button>}
                        {!productDescriptionExists && <Button primary url={editIndividualTabUrl}>Create Individual Tabs</Button>}
                      </div>
                    </Card>
                  </Layout.Section>
                  <Layout.Section>
                    <Card sectioned>
                      <div className="text-center">
                        <Layout>
                          <Layout.Section oneHalf>
                            <img className="block" src={tabTwoImage} />
                            <p className="mt-4"><TextStyle variation="strong">Common Tabs are used for content that applies to multiple products</TextStyle></p>
                            <p><TextStyle>Create a single Common Tab and apply it to multiple products</TextStyle></p>
                            <br />
                            <p className="mb-4"><TextStyle variation="subdued">Examples: 
                            <br />
                            Shipping, Material and After Care, FAQs, Support, Contact Form</TextStyle></p>
                            <Button url='/new/static' primary>Create Common Tab</Button>
                          </Layout.Section>
                          <Layout.Section oneHalf>
                            <img className="block pt-4 pb-4" src={tabThreeImage} />
                            <p className="mt-4"><TextStyle variation="strong">App Integration Tabs are used to insert 3rd party code </TextStyle></p>
                            <p><TextStyle>Create a single App Integration Tab and apply it to multiple products</TextStyle></p>
                            <br />
                            <p className="mb-4"><TextStyle variation="subdued">Examples: 
                            <br />
                            Product Reviews, Sizing Chart, Judge.me, Yotpo</TextStyle></p>
                            <Button url='/new/app_integration' primary>Create App Integration Tab</Button>
                          </Layout.Section>
                        </Layout>
                      </div>
                    </Card>
                  </Layout.Section>
                </Layout>
                <FooterLink></FooterLink>
            </div>
          )}

          {this.state.selectedTabIndex === 2 && (
              <div className="page-tabs mt-4">
                <Card sectioned>
                  <div>
                        <Heading>Publish Page Tabs</Heading>
                        <List type="number">
                          <List.Item>
                            Your theme does not seem to support Shopify 2.0. For a easier installation experience, we recommend that you update your theme or switch to one that supports Shopify 2.0.
                          </List.Item>
                          <List.Item>
                            <p>If you have not enabled the app in Theme Settings, <Link external url={this.state.themeSettingsURL}>click here to go there.</Link> In the 'App Embeds' tab on the right sidebar of the theme settings, you can toggle the switch beside 'Product &amp; Page Tabs' on.</p>
                          </List.Item>
                          <List.Item>
                            Here in the app, you can click on the 'Embed' button beside the set of page tabs to copy the embed code in the popup. This embed code can be pasted into different places on your website.
                          </List.Item>
                          <List.Item>
                            For example, you can paste it into Shopify's page or product description editor in HTML mode. You can click on the '&lt;&gt;' button on the editor toolbar to go into HTML mode.
                          </List.Item>
                          <List.Item>
                            You can also add the tabs in the Theme Customisation interface. Go to the template you are looking to install the tabs e.g. 'Home Page'. Click on 'Add section' or 'Add block' on the left sidebar and choose 'Custom Liquid'. Paste the embed code in and save.
                          </List.Item>
                          
                          </List>
                      </div>
                 </Card>
                 <hr />
                <DisplayText size="large">Page Tabs</DisplayText>
                {this.state.pageTabs.length > 0 && (
                  <div>
                    <Layout>
                    <Layout.AnnotatedSection
                      description="Page Tab Sections are grouped content you can insert anywhere in your store"
                    >
                      <Card>
                        <FormLayout>
                            <DataTable
                                columnContentTypes = {[
                                  'text', 'text'
                                ]}
                                  headings={['Name', 'Actions']}
                                  rows={pageTabRows}
                              />
                        </FormLayout>
                      </Card>
                    </Layout.AnnotatedSection>
                    </Layout>
                    <hr />
                  </div>
                )}
                <div className="text-center">
                <p className="mt-4 mb-4">
                <TextStyle variation="subdued">
                Create and style a Page Tab Section. Insert it's embed code into your store.
                </TextStyle>
                </p>
                  <Layout>
                    <Layout.Section oneHalf>
                      <Card sectioned>
                      <img className="block" src={pageTabOneImage} />
                      <p className="mt-4"><TextStyle variation="strong">Create a Page Tab Section</TextStyle></p>
                      <p><TextStyle>Page Tab Sections are grouped content you can insert anywhere in your store</TextStyle></p>
                      <p><Button primary url="/new-page-component/tabs">Create Page Tab Section</Button>
                      </p>
                      </Card>
                    </Layout.Section>
                    <Layout.Section oneHalf>
                      <Card sectioned>
                        <img className="block" src={pageTabTwoImage} />
                        <p className="mt-4"><TextStyle variation="strong">Insert embed code into store page</TextStyle></p>
                        <p><TextStyle>Copy the Page Tab Section's embed code and <br></br>paste it into your page's content via the HTML editor</TextStyle></p>
                        <Link onClick={this.openEmbedHelpModal}>Learn more</Link>
                      </Card>
                    </Layout.Section>
                  </Layout>
                </div>
                <FooterLink></FooterLink>
              </div>
          )}
            
            {this.state.selectedTabIndex === 1 && (
                <div className="tab-style">
                  <Card sectioned>
                    {!this.state.themeSupportAppExtensions &&
                      <div>
                          <Heading>Publish Product Tabs</Heading>
                          <List type="number">
                            <List.Item>
                              Your theme does not seem to support Shopify 2.0. For a easier installation experience, we recommend that you update your theme or switch to one that supports Shopify 2.0. This ensures future compatibility as well, for both the app and Shopify's future functionalities. However, if you wish to continue, you can follow the steps below. Alternatively, please contact us via the chat icon on the lower right hand corner of the screen, and we can help you as soon as we can.
                            </List.Item>
                            <List.Item>
                              <p>First, <Link external url={this.state.themeSettingsURL}>go to Theme Settings by clicking here.</Link> In the 'App Embeds' tab on the right sidebar of the theme settings, you can toggle the switch beside 'Product &amp; Page Tabs' on.</p>
                            </List.Item>
                            <List.Item>
                              Then you can go to Themes &gt; Theme &gt; Edit Code, and go into the product template/s you are using. This is usually under the Templates folder and named 'product.liquid'. If you are using multiple product templates, you need to repeat the next step for all the templates you are using.
                            </List.Item>
                            <List.Item>
                              <p>Click on the 'Embed Code' button below and copy the code in the popup. Then paste the code at the end of the product template/s in the Edit Code interface mentioned in step 4.</p>
                              <Button onClick={()=>{this.openProductTabsEmbedCodeModal()}}primary size="slim">Embed Code</Button>
                            </List.Item>
                            <List.Item>
                              <p>Finally, you can copy the following 'HTML Snippet' and paste it into the section of the product template/s you want the tabs to appear.</p>
                              <Button onClick={()=>{this.openHTMLSnippetCodeModal()}}primary size="slim">HTML Code</Button>
                            </List.Item>
                          </List>
                      </div>}
                      {this.state.themeSupportAppExtensions &&
                        <div>
                          <Heading>Publish Product Tabs</Heading>
                          <List type="number">
                            <List.Item>
                              <p>First, <Link external url={this.state.themeSettingsURL}>go to Theme Settings by clicking here.</Link> In the 'App Embeds' tab on the right sidebar of the theme settings, you can toggle the switch beside 'Product &amp; Page Tabs' on.</p>
                            </List.Item>
                            <List.Item>In the Theme Customisation interface, go to the product template you are looking to install the tabs e.g. 'Default Product'. Click on 'Add section' or 'Add block' on the left sidebar and choose 'Product Tabs' to add the tabs to your desired location. { !hasAppIntegrationTabs && <span>Save it.</span>}</List.Item>
                            { hasAppIntegrationTabs && 
                              <div>
                              <List.Item>
                              <p>To enable the App Integration tabs, click on the 'Embed' button below and copy the code in the popup. </p>
                                <Button onClick={()=>{this.openAppIntegrationEmbedCodeModal()}}primary size="slim">Embed</Button></List.Item>
                                <List.Item>
                                Go to the Theme Customisation interface, and replace the contents of the 'App Integration Liquid Code' on the right sidebar of the 'Product Tabs' settings, with the embed code. Then save it and you are set!
                                </List.Item>
                              </div>
                            }
                          </List>
                        </div>
                      }
                    </Card>
                  {this.state.isRefreshing && (<div className="main-spinner"><Spinner size="small" /></div>)}
                  <hr />
                    <Stack distribution="equalSpacing">
                      <Stack.Item>
                        <DisplayText size="small"><strong>Style Settings</strong></DisplayText>
                      </Stack.Item>
                      <Stack.Item>
                            <Stack alignment="center" distribution="trailing">
                              <Button primary disabled={!this.state.hasThemeChanged} loading={this.state.isSavingTheme} onClick={()=>this.saveTheme()}>{this.state.hasThemeChanged ? 'Save' : 'Saved'}</Button>
                            </Stack>
                      </Stack.Item>
                    </Stack>
                    <TextStyle variation="subdued"><p>Edit your styles below and save your changes when you are ready</p></TextStyle>
                 <br></br>
                  {this.state.isRefreshing && (<div className="main-spinner"><Spinner size="small" /></div>)}
                  <Layout>
                    <Layout.Section>
                      <ThemeEditor 
                        onThemeChange={this.handleThemeChange}
                        theme={this.state.theme}
                        style={this.state.style}
                        desktopType={this.state.desktopType}
                        mobileType={this.state.mobileType}
                        defaultColor={this.state.defaultColor}
                        defaultBgColor={this.state.defaultBgColor}
                        defaultFontWeight={this.state.defaultFontWeight}
                        activeColor={this.state.activeColor}
                        activeBgColor={this.state.activeBgColor}
                        activeFontWeight={this.state.activeFontWeight}
                        strokeColor={this.state.strokeColor}
                        bgColor={this.state.bgColor}
                        textColor={this.state.textColor}
                        iconType={this.state.iconType}
                        iconDefaultColor={this.state.iconDefaultColor}
                        iconActiveColor={this.state.iconActiveColor}
                        accordionDefaultCollapsed={this.state.accordionDefaultCollapsed}
                        accordionAllowMultipleExpand={this.state.accordionAllowMultipleExpand}
                        accordionDefaultExpandAll={this.state.accordionDefaultExpandAll}>
                      </ThemeEditor>
                      <div className="mt-20">
                        <Card subdued title="Custom Theme">
                          <Card.Section>
                          <p><TextStyle>If you are using a custom theme, and nothing shows up, check that the &#123;&#123; product.description &#125;&#125; Liquid tag is in a &lt;div&gt; with class 'vw-product-description'.</TextStyle></p>
                          </Card.Section>
                          <Card.Section subdued title="Support">
                          <p><TextStyle>If you encounter any issues, please feel free to contact us at <Link external url="mailto:hello@voidworks.co?subject=Support on Custom Product Accordion Tabs">hello@voidworks.co</Link>. We can help you tweak your theme code.</TextStyle></p>
                          </Card.Section>
                        </Card>
                      </div>
                    </Layout.Section>
                    <Layout.Section secondary>
                      <Card title="Style Preview">
                        <Card.Section>
                          <Card.Subsection>
                          {shouldShowPreview && <TabPreview ref={this.previewRef} tabs={this.state.tabs} theme={this.state.theme}></TabPreview>}

                          {!shouldShowPreview && <p className="mt-4 text-center"><TextStyle variation="subdued">No enabled tabs available for preview. </TextStyle><Link onClick={() => this.handleTabChange(0)}>Add or enable tabs</Link>.</p>}

                          </Card.Subsection>
                        </Card.Section>
                        <Card.Section>
                          <Card.Subsection>
                          <TextStyle variation="subdued">Fonts and font sizes will follow the theme that you are using. Individual Tabs specific to each product will not be shown in the preview, but will be present on your shop.</TextStyle>
                          </Card.Subsection>
                        </Card.Section>
                      </Card>
                    </Layout.Section>
                    
                  </Layout>
              </div>
              )}

              {this.state.selectedTabIndex === 3 && (
                <div className="tab-faq">
                  <DisplayText size="large">Support</DisplayText>
                  <Support onChangeTab={this.handleTabChange}></Support>
                  <FooterLink></FooterLink>
                </div>
              )}
          </React.Fragment>)}

          <Modal 
                open={this.state.shouldOpenEmbedAppIntegrationModal}
                onClose={this.onCloseEmbedAppIntegrationModal}
                primaryAction={
                  {
                    content: this.state.copyToClipboardText,
                    onAction: this.copyAppIntegrationEmbedCode
                  }
                }
                title="Embed Code for App Integration Tabs">
                <Card sectioned>
                  <FormLayout>
                    <p>Copy the following code and paste it into the 'App Integration Liquid Code' field in the 'Product Tabs' app block you have added in Theme Customisation.</p>
                    <TextStyle variation="code">{ appIntegrationEmbedCode }</TextStyle>
                  </FormLayout>
                </Card>
          </Modal>

          <Modal 
                open={this.state.shouldOpenProductTabsEmbedCodeModal}
                onClose={this.onCloseProductTabsEmbedCodeModal}
                primaryAction={
                  {
                    content: this.state.copyToClipboardText,
                    onAction: this.copyProductTabsEmbedCode,
                  }
                }
                title="Embed Code for Product Tabs">
                <Card sectioned>
                  <FormLayout>
                    <TextStyle variation="code">{ productTabsEmbedCode }</TextStyle>
                  </FormLayout>
                </Card>
          </Modal>

          <Modal 
                open={this.state.shouldOpenHTMLSnippetCodeModal}
                onClose={this.onCloseHTMLSnippetCodeModal}
                primaryAction={
                  {
                    content: this.state.copyToClipboardText,
                    onAction: this.copyHTMLSnippetCode,
                  }
                }
                title="HTML Snippet for Product Tabs">
                <Card sectioned>
                  <FormLayout>
                    <TextStyle variation="code">{ HTMLSnippetCode }</TextStyle>
                  </FormLayout>
                </Card>
          </Modal>

          <Modal
            open={this.state.modalActive}
            onClose={this.toggleModalActive}
            title="How to create Individual Tabs with Headings"
            primaryAction={{
              content: 'Done',
              onAction: this.toggleModalActive,
            }}
          >
          <HeadingInfoModal></HeadingInfoModal>
          </Modal>

          {this.state.pageTabs.length > 0 && 
            <Modal 
                open={this.state.shouldOpenEmbedModal}
                onClose={this.onCloseEmbedModal}
                primaryAction={
                  {
                    content: this.state.copyToClipboardText,
                    onAction: this.copyEmbedCode,
                  }
                }
                title={'Embed ' + this.state.pageTabs[this.state.currentEmbedIndex].name}>
                <Card sectioned>
                  <FormLayout>
                    <p>Copy the following code and paste it into a store page</p>
                    <TextStyle variation="code">&lt;div data-id='{this.state.pageTabs[this.state.currentEmbedIndex].uuid}' data-name='{this.state.pageTabs[this.state.currentEmbedIndex].name}' class='vw-page-tabs id{this.state.pageTabs[this.state.currentEmbedIndex].uuid}'&gt;Loading...&lt;/div&gt;</TextStyle>
                  </FormLayout>
                </Card>
            </Modal>
          }

            <Modal 
                open={this.state.shouldOpenEmbedHelpModal}
                onClose={this.onCloseEmbedHelpModal}
                secondaryActions={[
                  {
                    content: 'Close',
                    onAction: this.onCloseEmbedHelpModal,
                  },
                ]}
                title="How to embed Page Tabs into your store">
                <Modal.Section>
                  <p>You will need to insert your Page Tab Section's embed code into your store for it to appear. It can be inserted anywhere in your store, e.g. pages, blog posts, collection pages. </p>
                  <hr />
                    <Heading>Insert code into a store page:</Heading>
                    <br />
                    <p>1. In Page Tabs, click the <strong>Embed</strong>  to open up the embed code dialog.</p>
                    <img className="full-width" src={screen2a} />
                    <p>2. Click <strong>Copy to Clipboard</strong> and close the embed code dialog.</p>
                    <img className="full-width" src={screen2b} />
                    <p>3. From your Shopify admin, go to <strong>Online Store &gt; Pages</strong>.</p>
                    <p>4. Click the name of the page where you want to add the Page Tab Section or click 'Add page' to create a new page.</p>
                    <p>5. Under <strong>Content</strong>, click on <strong>Show HTML</strong> icon button at the top right corner of the rich text editor.</p>
                    <img className="full-width" src={screen2c} />
                    <p>6. Paste the embedded code copied to where you want it to load.</p>
                    <p><strong>Tip:</strong> Always add or edit the entire line of code from the HTML editor view. The <TextStyle variation="code">data-name</TextStyle> attribute corresponds to the Page Tab Sections's title.</p>
                    <img className="full-width" src={screen2d} />
                    <p>7. Click <strong>Save</strong>.</p>
                    <hr />
                    <p>The Page Tab Section embedded will be reflected in your store page if you have turned on publish at the '<strong>Page Tabs</strong>' dashboard.</p>
                    <img className="full-width" src={screen2e} />
                </Modal.Section>
            </Modal>
          
          <Modal
              open={this.state.shouldOpenDeleteComponentModal}
              onClose={this.onCloseDeleteComponentModal}
              title="Delete Tab"
              primaryAction={{
                content: 'Delete',
                onAction: this.confirmDeleteComponent,
                destructive: true,
                disabled: this.state.deleteComponentLoading,
              }}
              secondaryActions={[
                {
                  content: 'Cancel',
                  onAction: this.onCloseDeleteComponentModal,
                  disabled: this.state.deleteComponentLoading
                },
              ]}
            >
            <Modal.Section>
              <TextStyle>Are you sure you want to delete {toBeDeletedComponentName}? This is irreversible.</TextStyle>
            </Modal.Section>
          </Modal>

          <Modal
              open={this.state.shouldOpenDeleteModal}
              onClose={this.onCloseDeleteModal}
              title="Delete Tab"
              primaryAction={{
                content: 'Delete',
                onAction: this.confirmDelete,
                destructive: true,
                disabled: this.state.deleteLoading,
              }}
              secondaryActions={[
                {
                  content: 'Cancel',
                  onAction: this.closeDeleteModal,
                  disabled: this.state.deleteLoading
                },
              ]}
            >
            <Modal.Section>
              <TextStyle>Are you sure you want to delete {toBeDeletedTitle}? This is irreversible and the tab will be uninstalled from your pages.</TextStyle>
            </Modal.Section>
          </Modal>

      </React.Fragment>
    );
  }
}

export default TabListing;

import React, { Component } from 'react';
import { Card, List, TextStyle, DisplayText, Link, Layout } from '@shopify/polaris';

interface SupportProps {
    onChangeTab: Function
}

class Support extends Component <SupportProps> {

    handleOnChangeTab = (index: number) => {
        this.props.onChangeTab(index);
    }

    render() {
        return (
            <div>
                <Layout>
                    <Layout.Section>
                        <Card title="Frequently Asked Questions">
                            <Card.Section >
                            <List type="number">
                                <TextStyle variation="strong"><List.Item>Why can't I see the tabs after I publish a new theme?</List.Item></TextStyle>
                                <p>By default, we install the code in the main published theme. When the published theme is changed, the code might not be present yet. All you have to do is to toggle the publish switch under 'Style/Publish Product Tabs' off and on, and you are good to go.<br/>If you are using a theme that supports app extensions, you can follow the instructions under Style/Publish Product Tabs.<br/><br/></p>
                                <TextStyle variation="strong"><List.Item>Why can't I see the tabs in my custom uploaded theme?</List.Item></TextStyle>
                                <p>Custom Product Accordion Tabs works on all standard free Shopify themes. However, your custom theme might not follow the same convention. Simply ask your developer to add this class - 'vw-product-description' - to the container containing the product description and you should see it working.<br/><br/></p>
                                <TextStyle variation="strong"><List.Item>I can't contact my developer and I don't understand your technical terms, are you able to help?</List.Item></TextStyle>
                                <p>Yes! Please contact us at <Link external url="mailto:hello@voidworks.co?subject=Support on Custom Product Accordion Tabs">hello@voidworks.co</Link> and we will help you with this at no extra cost. Please share your shopify domain with us in the email. Depending on the issue, we might request access to your shop (apps, themes, products and pages only)<br/><br/></p>
                            </List>
                            </Card.Section>
                        </Card>
                    </Layout.Section>
                    <Layout.Section secondary>
                        <Card sectioned title="Contact Us">
                            <p>If you have any questions about Custom Product Accordion Tabs, we are here to help!</p>
                            <p>If you require further assistance or if you have any suggestions for improving the app, please contact us at <Link external url="mailto:hello@voidworks.co?subject=Support on Custom Product Accordion Tabs">hello@voidworks.co</Link>!</p>
                        </Card>
                    </Layout.Section>
                  </Layout>
            </div>
        );
    }
}

export default Support;
import React, { Component } from 'react';
import Resizer from 'react-image-file-resizer';
import Toggle from 'react-toggle';
import ThemeEditor from './ThemeEditor';
import TabPreview from './TabPreview';
import {Controlled as CodeMirror} from 'react-codemirror2'
import { Icon, Page, Card, TextField, FormLayout, Link, Button, Stack, TextStyle, Heading, Layout, Spinner, Modal, DisplayText } from '@shopify/polaris';
import {Loading} from '@shopify/app-bridge-react';
import { getFetch, postFetch, putFetch, postUploadFetch } from '../helpers/request';
import { Redirect } from 'react-router';
import 'codemirror/lib/codemirror';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/theme/monokai.css';
import 'codemirror/lib/codemirror.css';
import 'react-summernote/dist/react-summernote.css';
import ReactSummernote from 'react-summernote';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import DragAndDropTable from './draggable-table/DragAndDropTable';
import './TabListing.css';

interface PageComponentProps {
  type: string,
  id?: string,
  componentType?: string
}

interface PageComponentState {
  hasLoaded: boolean,
  errorLoading: boolean,
  componentType: string,
  name: string,
  data: any,
  isSaving: boolean,
  nameTouched: boolean,
  redirectToListing: boolean,
  redirectId: string,
  showBanner:boolean,
  modalActive: boolean,
  shouldOpenDeleteModal: boolean,
  uuid: string,
  currentHtml: string,
  currentItemIndex: number,
  currentTabTitle: string,
  currentUUID: string,
  deleteIndex: number,
  hasThemeChanged:boolean,
  theme: any,
  //theme
  style: string,
  desktopType: string,
  mobileType: string,
  defaultColor: any,
  defaultBgColor: any,
  defaultFontWeight: string,
  activeColor: any,
  activeBgColor: any,
  activeFontWeight: string,
  strokeColor: any,
  bgColor: any,
  textColor: any,
  iconType: string,
  iconDefaultColor: any,
  iconActiveColor: any,
  accordionDefaultCollapsed: boolean,
  accordionAllowMultipleExpand: boolean,
  accordionDefaultExpandAll: boolean,

}

class PageComponent extends Component <PageComponentProps, PageComponentState> {
  summernoteRef:any;
  previewRef:any;

  defaultTheme: any = {
    style: 'normal',
    desktopType: 'tabs',
    mobileType: 'accordion',
    iconType: 'plus',
    iconDefaultColor: {r:150,g:150,b:150,a:1},
    iconActiveColor: {r:103,g:103,b:103,a:1},
    defaultColor: {r:150,g:150,b:150,a:1},
    defaultBgColor: {r:236,g:236,b:236,a:1},
    defaultFontWeight: 'normal',
    activeColor: {r:103,g:103,b:103,a:1},
    activeBgColor: {r:255,g:255,b:255,a:1},
    activeFontWeight: 'bold',
    strokeColor: {r:230,g:230,b:230,a:1},
    bgColor: {r:255,g:255,b:255,a:1},
    textColor: {r:43,g:43,b:43,a:1},
    accordionDefaultCollapsed: false,
    accordionAllowMultipleExpand: false,
    accordionDefaultExpandAll: false
  };

  constructor(props: PageComponentProps) {
    super(props);
    this.state = { 
      theme: {},
      modalActive: false,
      shouldOpenDeleteModal: false,
      isSaving: false,
      hasLoaded: false,
      errorLoading: false,
      redirectToListing: false,
      redirectId: '',
      showBanner: true,
      name: '',
      nameTouched: false,
      data: {},
      componentType: 'tab',
      uuid: '',
      currentHtml: '',
      currentItemIndex: -1,
      currentTabTitle: '',
      currentUUID: '',
      deleteIndex: -1,
      //theme
      style: '',
      desktopType: '',
      mobileType: '',
      defaultColor: {},
      defaultBgColor: {},
      defaultFontWeight: '',
      activeColor: {},
      activeBgColor: {},
      activeFontWeight: '',
      strokeColor: {},
      bgColor: {},
      textColor: {},
      iconDefaultColor: {},
      iconActiveColor: {},
      iconType: '',
      accordionDefaultCollapsed: false,
      accordionAllowMultipleExpand: false,
      accordionDefaultExpandAll: false,
      hasThemeChanged: false
    };
    this.previewRef = React.createRef();
  }
  

  componentDidMount() {
    if (this.props.type === 'new') {
      for (let key of Object.keys(this.defaultTheme)) {
        this.setState<never>({[key]: this.defaultTheme[key]});
      }
      this.setState({theme: this.defaultTheme});
      this.setState({hasLoaded: true});
    } else if (this.props.type === 'existing') {
      getFetch('/app/api/components/?id='+this.props.id, {})
        .then((res:any) => res.json()
        ).then(data => {
          let d = JSON.parse(data.data);
          let t = d.theme;
          this.setState({ name: data.name });
          this.setState({ uuid: data.uuid })
          
          this.setState({ componentType: data.type })
          this.setState({ hasLoaded: true });
          let toUpdateDefault = false;
          for (let key of Object.keys(this.defaultTheme)) {
            if (!(key in t)) {
              t[key] = this.defaultTheme[key];
              toUpdateDefault = true;
            }
          }
          if (toUpdateDefault) {
            d.theme = t;
            this.setState({ data: d });
            let body:any = {
              name: data.name,
              data: JSON.stringify(d),
            };
            body.id = this.props.id;
            putFetch('/app/api/components/', body).then((res) => {});
          }
          for (let key of Object.keys(this.defaultTheme)) {
            this.setState<never>({[key]: t[key]});
          }
          this.setState({ theme: t }, ()=> {
            this.setState({ data: d });
          });
        });
    }
  }

  handleThemeChange = (selected:any, id:string) => {
    this.setState({hasThemeChanged: true});
    this.setState<never>({[id]: selected});
    let theme = Object.assign({}, this.state.theme);
    theme[id] = selected;
    if (id === 'style') {
      if (selected === 'normal') {
        theme.defaultColor = {r:150, g:150, b:150, a:1};
        theme.activeColor = {r:103, g:103, b:103, a:1};
        theme.defaultBgColor = {r:236, g:236, b:236, a:1};
        theme.activeBgColor = {r:255, g:255, b:255, a:1};
        theme.textColor = {r:43, g:43, b:43, a:1};
        theme.strokeColor = {r:230, g:230, b:230, a:1};
        theme.bgColor = {r:255, g:255, b:255, a:1};
        theme.iconActiveColor = {r:103,g:103,b:103,a:1};
        theme.iconDefaultColor = {r:150,g:150,b:150,a:1};
      } else if (selected === 'underline') {
        theme.defaultColor = {r:43, g:43, b:43, a: 1};
        theme.activeColor = {r:43, g:43, b:43, a: 1};
        theme.defaultBgColor = {r:236, g:236, b:236, a:0};
        theme.activeBgColor = {r:255, g:255, b:255, a:0};
        theme.textColor = {r:0, g:0, b:0, a:1};
        theme.bgColor = {r:255, g:255, b:255, a:0};
        theme.strokeColor = {r:230, g:230, b:230, a:0};
        theme.iconActiveColor = {r:43, g:43, b:43, a: 1};
        theme.iconDefaultColor = {r:150,g:150,b:150,a:1};
      } else if (selected === 'link') {
        theme.defaultColor = {r:121, g:121, b:121, a:1};
        theme.defaultBgColor = {r:236, g:236, b:236, a:0};
        theme.activeColor = {r:43, g:43, b:43, a: 1};
        theme.activeBgColor = {r:255, g:255, b:255, a:0};
        theme.bgColor = {r:255, g:255, b:255, a:0};
        theme.strokeColor = {r:230, g:230, b:230, a:0};
        theme.textColor = {r:43, g:43, b:43, a:1};
        theme.iconActiveColor = {r:103,g:103,b:103,a:1};
        theme.iconDefaultColor = {r:150,g:150,b:150,a:1};
      } else if (selected === 'box') {
        theme.defaultColor = {r:43, g:43, b:43, a: 1};
        theme.defaultBgColor = {r:239, g:239, b:239, a: 1};
        theme.activeColor = {r:43, g:43, b:43, a: 1};
        theme.activeBgColor = {r:249, g:249, b:249, a: 1};
        theme.bgColor = {r:249, g:249, b:249, a: 1};
        theme.strokeColor = {r:230, g:230, b:230, a:0};
        theme.textColor = {r:43, g:43, b:43, a:1};
        theme.iconActiveColor = {r:103,g:103,b:103,a:1};
        theme.iconDefaultColor = {r:150,g:150,b:150,a:1};
      } else if (selected === 'pill') {
        theme.defaultColor = {r:43, g:43, b:43, a: 1};
        theme.defaultBgColor = {r:239, g:239, b:239, a: 1};
        theme.activeColor = {r:255, g:255, b:255, a: 1};
        theme.activeBgColor = {r:43, g:43, b:43, a: 1};
        theme.bgColor = {r:236, g:236, b:236, a:0};;
        theme.strokeColor = {r:230, g:230, b:230, a:0};
        theme.textColor = {r:43, g:43, b:43, a:1};
        theme.iconActiveColor = {r:255, g:255, b:255, a:1};
        theme.iconDefaultColor = {r:43,g:43,b:43,a:1};
      }
      this.setState({iconActiveColor: theme.iconActiveColor});
      this.setState({iconDefaultColor: theme.iconDefaultColor});
      this.setState({defaultColor: theme.defaultColor});
      this.setState({defaultBgColor: theme.defaultBgColor});
      this.setState({activeColor: theme.activeColor});
      this.setState({activeBgColor: theme.activeBgColor});
      this.setState({textColor: theme.textColor});
      this.setState({bgColor: theme.bgColor});
      this.setState({strokeColor: theme.strokeColor});
    }
    this.setState({theme});
    if (id === 'desktopType' || id === 'mobileType' || id === 'accordionDefaultCollapsed' || id === 'accordionAllowMultipleExpand' || id === 'accordionDefaultExpandAll') {
      if (this.previewRef.current) {
        this.previewRef.current.reset(theme);
      }
    } else {
      if (this.previewRef.current) {
        this.previewRef.current.resetStyles(theme);
      }
    }
  };

  toggleModalActive = () => {
    let active = this.state.modalActive;
    this.setState({modalActive: !active});
  }

  saveItem = () => {
    let newHtml = this.summernoteRef.editor.summernote('code');
    if (this.state.currentItemIndex === -1) {
      // new item
      let tabTitle = this.state.currentTabTitle;
      let item = {
        tabTitle: tabTitle,
        html: newHtml,
        enabled: true,
        uuid: this.createUUID(),
        contentType: 'static'
      }
      let data = this.state.data;
      if (!data.items) {
        data.items = [];
      }
      data.items.push(item);
      this.setState({data: data}, () => {
        if (this.previewRef.current) {
          this.previewRef.current.reset(this.state.theme);
        }
      });
      this.resetItemState();
      this.toggleModalActive();
    } else {
      let data = this.state.data;
      data.items[this.state.currentItemIndex].tabTitle = this.state.currentTabTitle;
      data.items[this.state.currentItemIndex].html = newHtml;
      this.setState({data: data}, () => {
        if (this.previewRef.current) {
          this.previewRef.current.reset(this.state.theme);
        }
      });
      this.resetItemState();
      this.toggleModalActive();
    }
    
  }

  resetItemState = () => {
    this.setState({currentTabTitle: ''});
    this.setState({currentHtml: ''});
    this.setState({currentItemIndex:-1});
    this.setState({currentUUID: ''});
  }

  save = () => {
    this.setState({isSaving:true});
    let data = this.state.data;
    data.theme = this.state.theme;
    
    let body:any = {
      name: this.state.name,
      data: JSON.stringify(data),
    };
    
    if (this.props.type === 'new') {
      postFetch('/app/api/components/', body)
      .then((res:any) => res.json()).then(
        (data) => { 
          this.setState({redirectToListing: true});
        }
      ).catch( 
        (err) => console.log(err)
      );
    } else if (this.props.type === 'existing') {
      body.id = this.props.id;
      putFetch('/app/api/components/', body).then(
        (res) => { 
          this.setState({redirectToListing: true});
        }
      ).catch( 
        (err) => console.log(err)
      );
    }
    
  }

  handleNameChange = (value:string) => {
    this.setState({nameTouched: true});
    this.setState({name: value});
  };
  

  handleImageUpload = (files:FileList) => {
    let formData = new FormData();
    let filesToUpload: Array<Blob> = [];
    if (files) {
      for (let i=0; i<files.length; i++) {
        Resizer.imageFileResizer(
          files[i],
          1500,
          1500,
          'PNG',
          100,
          0,
          (blob: any) => {
            filesToUpload.push(blob);
            if (i===files.length-1) {
              for (let f of filesToUpload) {
                formData.append('files', f);
              }
              if (this.props.id) {
                formData.append('component', this.props.id + '');
              }
              postUploadFetch('/app/api/component-image/', formData)
                .then((res:any) => res.json()
                ).then(data => {
                  for (let url of data.urls) {
                    ReactSummernote.insertImage(url, (image:any)=>{
                      image.css({'max-width': '100%'})
                    });
                  }
                }
              )
            }
          },
          'blob'
        );
      }
    }
  }

  onSummernoteChange = (content:any) => {
    this.setState({currentHtml: content});
  }

  handleOrderChange = (result: any) => {
    let items = this.reorder(
      this.state.data.items,
      result.source.index,
      result.destination.index
    );
    let data = this.state.data;
    data.items = items;
    this.setState({data: data}, () => {
      this.previewRef.current.reset(this.state.theme);
    });
  }

  reorder = (list:Array<any>, startIndex:number, endIndex:number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  handleTabTitleChange = (value:string) => {
    this.setState({currentTabTitle: value})
  }

  handleAddNewItem = () => {
    this.setState({currentItemIndex: -1});
    this.setState({currentHtml: ''});
    this.setState({currentTabTitle: ''});
    this.toggleModalActive();
  }

  handleEditItem = (index: number) =>  {
    this.setState({currentItemIndex: index});
    this.setState({currentHtml: this.state.data.items[index].html})
    this.setState({currentTabTitle: this.state.data.items[index].tabTitle})
    this.toggleModalActive();
  }

  handleItemToggle = (event:any, index:number) => {
    const items = this.state.data.items.map((item:any, j:number) => {
      if (j === index) {
        item.enabled = event.target.checked;
        return item;
      } else {
        return item;
      }
    });
    let data = this.state.data;
    data.items = items;
    this.setState({data}, () => {
      if (this.previewRef.current) {
        this.previewRef.current.reset(this.state.theme);
      }
    });
  }

  redirectBackToListing = () => {
    this.setState({redirectToListing:true});
  }

  openDeleteModal = (index: number) => {
    this.setState({shouldOpenDeleteModal: true});
    this.setState({deleteIndex: index});
  }

  closeDeleteModal = () => {
    this.setState({shouldOpenDeleteModal:false});
  }

  confirmDelete = () => {
    let deletingUuid = this.state.data.items[this.state.deleteIndex].uuid;
    let resultingItems:any[] = [];
    for (let item of this.state.data.items) {
      if (item.uuid !== deletingUuid){
        resultingItems.push(item);
      }
    }
    let data = this.state.data;
    data.items = resultingItems;
    this.setState({data}, () => {
      if (this.previewRef.current) {
        this.previewRef.current.reset(this.state.theme);
      }
    });
    this.setState({shouldOpenDeleteModal:false});
  }

  createUUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
  }

  render() {
    if (this.state.redirectToListing === true) {
      return <Redirect to='/tabs-listing?tabindex=2' />
    } 

    let shouldShowPreview = false;

    if (this.state.data.items) {
      for (let t of this.state.data.items) {
        if (t.enabled) {
          shouldShowPreview = true;
          break;
        }
      }
    }

    let rows:any[] = [];

    if (Object.keys(this.state.data).length > 0) {
      if (this.state.data.items) {
        this.state.data.items.forEach((item:any, index:number) => {
          let row = [
            item.uuid,
            <Icon key={item.uuid} source='<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="%23919eab" d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"/></svg>' />,
            <Link onClick={() => this.handleEditItem(index)}><TextStyle key={item.uuid}>{item.tabTitle}</TextStyle></Link>,
            <Toggle key={item.uuid} icons={false} onChange={(event)=> {this.handleItemToggle(event, index)}} defaultChecked={item.enabled}></Toggle>,
            <Button key={item.uuid} destructive size="slim" onClick={() => this.openDeleteModal(index)}>Delete</Button>
          ];
          rows.push(row);
        });
      }
    }

    return (
        <Page 
          title={this.props.type === 'new' ? "Create Page Tab Section" : "Edit Page Tab Section"}
          breadcrumbs={[{content: 'Page Tabs', onAction: this.redirectBackToListing}]}>
          {!this.state.hasLoaded && (<Loading />)}
          {!this.state.hasLoaded && (<div><Layout><Spinner size="large" /></Layout></div>)}
          {this.state.hasLoaded && (
            <div className="tab-style">
                <Layout>
                  <Layout.AnnotatedSection
                    title="Name"
                    description="This is used to identify your Page Tab Section for admin purposes only on your dashboard."
                  >
                    <Card sectioned>
                      <FormLayout>
                        <TextField
                          label="Enter Name"
                          value={this.state.name}
                          onChange={this.handleNameChange}
                          error={this.state.nameTouched && this.state.name.length==0 ? "Name is required" : 
                                //!TabTitleRegex.test(this.state.TabTitle) ? "Tab name contains illegal characters" :
                                false}
                          placeholder="e.g. FAQ, Shipping"
                        />
                      </FormLayout>
                    </Card>
                  </Layout.AnnotatedSection>
                  </Layout>
                  <hr />
                  <Layout>
                  <Layout.Section>
                    <Stack distribution="equalSpacing">
                    <DisplayText size="small"><strong>Page Tab Section Items</strong></DisplayText>
                      <Button onClick={this.handleAddNewItem} primary>Add New Item</Button>
                    </Stack>
                    <TextStyle variation="subdued">
                    <p className="mt-4">Create and manage your content. Drag and drop to reorder the sequence in which they appear.</p></TextStyle>
                    {rows.length === 0 &&
                      <Card sectioned subdued>
                        <p className="text-center mt-4 mb-4"><TextStyle variation="subdued">This page tab section doesn't have any items. Click 'Add New Item' to create one.</TextStyle></p>
                      </Card>
                    }
                    {rows.length > 0 && 
                      <Card>
                        <DragAndDropTable
                            onOrderChange={this.handleOrderChange}
                            headers={['', 'Title', 'Enabled', 'Actions']}
                            percentageWidths = {[10,50,20,20]}
                            rows={rows}
                        />
                      </Card>
                    }
                  </Layout.Section>
                  </Layout>
                  <hr />
                  <Layout>
                  <Layout.Section>
                    <ThemeEditor 
                      onThemeChange={this.handleThemeChange}
                      theme={this.state.theme}
                      style={this.state.style}
                      desktopType={this.state.desktopType}
                      mobileType={this.state.mobileType}
                      defaultColor={this.state.defaultColor}
                      defaultBgColor={this.state.defaultBgColor}
                      defaultFontWeight={this.state.defaultFontWeight}
                      activeColor={this.state.activeColor}
                      activeBgColor={this.state.activeBgColor}
                      activeFontWeight={this.state.activeFontWeight}
                      strokeColor={this.state.strokeColor}
                      bgColor={this.state.bgColor}
                      textColor={this.state.textColor}
                      iconType={this.state.iconType}
                      iconDefaultColor={this.state.iconDefaultColor}
                      iconActiveColor={this.state.iconActiveColor}
                      accordionDefaultCollapsed={this.state.accordionDefaultCollapsed}
                      accordionAllowMultipleExpand={this.state.accordionAllowMultipleExpand}
                      accordionDefaultExpandAll={this.state.accordionDefaultExpandAll}>
                    </ThemeEditor>
                  </Layout.Section>
                  <Layout.Section secondary>
                      <Card title="Style Preview">
                        <Card.Section>
                          <Card.Subsection>
                          {shouldShowPreview && <TabPreview ref={this.previewRef} tabs={this.state.data.items} theme={this.state.theme}></TabPreview>}

                          {!shouldShowPreview && <p className="mt-4 text-center"><TextStyle variation="subdued">No enabled items available for preview. </TextStyle></p>}

                          </Card.Subsection>
                        </Card.Section>
                        <Card.Section>
                          <Card.Subsection>
                          <TextStyle variation="subdued">Fonts and font sizes will follow the theme that you are using. Individual Tabs specific to each product will not be shown in the preview, but will be present on your shop.</TextStyle>
                          </Card.Subsection>
                        </Card.Section>
                      </Card>
                    </Layout.Section>
                    </Layout>
                    <Layout>
                  <Layout.Section>
                    <hr />
                    <Stack>
                      <Button onClick={this.redirectBackToListing}>Cancel</Button>
                      <Button primary loading={this.state.isSaving} disabled={this.state.name.length === 0} onClick={this.save}>{this.props.type==='new' ? 'Save Tab' : 'Update'}</Button>
                    </Stack>
                  </Layout.Section>
                  </Layout>
              </div>
          )}

          {this.state.deleteIndex >= 0 && Object.keys(this.state.data).length > 0 && 
            <Modal
                open={this.state.shouldOpenDeleteModal}
                onClose={this.closeDeleteModal}
                title="Delete Tab"
                primaryAction={{
                  content: 'Delete',
                  onAction: this.confirmDelete,
                  destructive: true,
                }}
                secondaryActions={[
                  {
                    content: 'Cancel',
                    onAction: this.closeDeleteModal,
                  },
                ]}
              >
              <Modal.Section>
                <TextStyle>Are you sure you want to delete {this.state.data.items[this.state.deleteIndex] ? this.state.data.items[this.state.deleteIndex].title : ''}? This is irreversible.</TextStyle>
              </Modal.Section>
            </Modal>
          }

          <Modal
            open={this.state.modalActive}
            onClose={this.toggleModalActive}
            title={this.state.currentItemIndex === -1 ? 'Create item' : 'Edit item'}
            primaryAction={{
              content: 'Save',
              onAction: this.saveItem,
              disabled: this.state.currentTabTitle.length === 0
            }}
          ><Modal.Section>
              <FormLayout>
                <TextField
                  label="Enter Item Title"
                  value={this.state.currentTabTitle}
                  onChange={this.handleTabTitleChange}
                  placeholder="e.g. How do I track my order?"
                />
                <TextStyle>Edit Item Content</TextStyle>
                <ReactSummernote
                    ref={(el:any) => this.summernoteRef = el}
                    onImageUpload={this.handleImageUpload}
                    value={this.state.currentHtml}
                    options={{
                      height: 350,
                      toolbar: [
                        ['style', ['style']],
                        ['font', ['bold', 'underline', 'clear']],
                        ['para', ['ul', 'ol', 'paragraph']],
                        ['table', ['table']],
                        ['insert', ['link', 'picture', 'video']],
                        ['view', ['codeview']]
                      ],
                      styleTags: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
                      prettifyHtml: true,
                      codemirror: { 
                        theme: 'monokai',
                        mode : 'htmlmixed',
                        lineNumbers: true,
                        lineWrapping: true,
                      },
                      //dialogsInBody: true
                    }}
                    onChange={this.onSummernoteChange}
                />
                <TextStyle variation="subdued">If you are uploading images larger than 1 Mb or GIF animations, you can upload it under Shopify's Settings &gt; Files and use the image URL instead.</TextStyle>
              </FormLayout>
              </Modal.Section>
          </Modal>
        </Page>
    );
  }
}

export default PageComponent;

import React, { Component } from 'react';
import 'jqueryui/jquery-ui.css'
import '../external/tabs.css';
import '../external/scrolltabs.css';
import { initTabs, destroyTabs, resetTabs, resetStyles } from '../external/tabs.js'
import { TextStyle, DisplayText} from '@shopify/polaris';

interface TabPreviewProps {
  tabs: Array<any>,
  theme: any
}

interface TabPreviewState {
  
}
class TabPreview extends Component <TabPreviewProps, TabPreviewState> {
    
    el:any;

    constructor(props: TabPreviewProps) {
        super(props);
        this.el = React.createRef();
    }

    componentDidMount() {
      this.init();
    }
  
    componentWillUnmount() {
      this.destroy();
    }

    init() {
      let previewTheme = {...this.props.theme};
      previewTheme.isPreview = true;
      initTabs(this.el, this.props.tabs, previewTheme);
    }

    destroy() {
      destroyTabs(this.el);
    }


    reset(theme:any) {
      let previewTheme = {...theme};
      previewTheme.isPreview = true;
      resetTabs(this.el, this.props.tabs, previewTheme);
    }

    resetStyles(theme:any) {
      let previewTheme = {...theme};
      previewTheme.isPreview = true;
      resetStyles(this.el, previewTheme);
    }

    render() {
      return (
        <div>
          {(!this.props.tabs) && (
            <TextStyle variation="subdued">Please add a tab title and some tab content</TextStyle>
          )}
          <div ref={el => this.el = el} />
        </div>
      );
    }
  }

  export default TabPreview;
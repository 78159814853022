import React, { Component } from 'react';
import './TabListingPage.css';
import {Provider} from '@shopify/app-bridge-react';
import {AppProvider, Page} from '@shopify/polaris';
import TabListing from '../components/TabListing';
import { getFetch } from '../helpers/request';

interface TabListingPageProps {
  match: any
}

interface TabListingPageState {
  shopOrigin: string,
  apiKey: string
}

class TabListingPage extends Component <TabListingPageProps, TabListingPageState> {

  constructor(props: TabListingPageProps) {
    super(props);
    this.state = { 
      shopOrigin: '',
      apiKey: ''
    };
  }

  componentDidMount() {
    //check if log in
    getFetch('/app/api/shopify/user', {})
    .then((res: any) => res.json()
    ).then(data => { 
      this.setState({ shopOrigin: data.shop_origin, apiKey: data.api_key })
    }).catch(err => {

    });
  }
  
  render() {
    const config = {apiKey: this.state.apiKey, shopOrigin: this.state.shopOrigin, host:Buffer.from('admin.shopify.com/store/' + this.state.shopOrigin.split('.')[0]).toString('base64')};    let tabIndex = this.props.match.params.tabIndex === undefined ? 0 : this.props.match.params.tabIndex;
    return (
      this.state.shopOrigin !== '' && this.state.apiKey !== '' &&
      <AppProvider i18n={{}}>
        <Provider config={config}>
          <Page title="">
            <TabListing selectedTabIndex={parseInt(tabIndex)}></TabListing>
          </Page>
        </Provider>
      </AppProvider>
    );
  }
}

export default TabListingPage;

import React, { Component } from 'react';
import './App.css';
import '@shopify/polaris/dist/styles.css';

import { BrowserRouter as Router, Route } from "react-router-dom";
import TabListingPage from "./pages/TabListingPage";
import NewTabPage from "./pages/NewTabPage";
import TabPage from "./pages/TabPage";
import PrivacyPage from "./pages/PrivacyPage";
import NewPageComponentPage from "./pages/NewPageComponentPage";
import PageComponentPage from "./pages/PageComponentPage";
import LoginPage from "./pages/LoginPage";

interface AppProps {

}

interface AppState {

}

class App extends Component <AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
  }

  componentDidMount() {}
  
  render() {
    return (
      <Router>
          <Route exact path="/tabs-listing" component={TabListingPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/tabs/:tabIndex?" component={TabListingPage} />
          <Route exact path="/new/:contentType" component={NewTabPage} />
          <Route exact path="/new-page-component/:componentType" component={NewPageComponentPage} />
          <Route path="/product-tabs/:tabId" component={TabPage} />
          <Route path="/page-components/:componentId" component={PageComponentPage} />
          <Route exact path="/privacy" component={PrivacyPage} />
      </Router>
    );
  }
}

export default App;

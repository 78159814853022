import React, { Component } from 'react';
import {Provider} from '@shopify/app-bridge-react';
import {AppProvider} from '@shopify/polaris';
import PageComponent from '../components/PageComponent';
import { getFetch } from '../helpers/request';

interface NewPageComponentPageProps {
  componentType: string,
  match: any
}

interface NewPageComponentPageState {
  shopOrigin: string,
  apiKey: string
}

class NewPageComponentPage extends Component <NewPageComponentPageProps, NewPageComponentPageState> {

  constructor(props: NewPageComponentPageProps) {
    super(props);
    this.state = { 
      shopOrigin: '',
      apiKey: ''
    };
  }

  componentDidMount() {
    //check if log in
    getFetch('/app/api/shopify/user', {})
    .then((res: any) => res.json()
    ).then(data => { 
      this.setState({ shopOrigin: data.shop_origin, apiKey: data.api_key })
    }).catch(err => {

    });
  }
  
  render() {
    const config = {apiKey: this.state.apiKey, shopOrigin: this.state.shopOrigin, host:Buffer.from('admin.shopify.com/store/' + this.state.shopOrigin.split('.')[0]).toString('base64')};    return (
      this.state.shopOrigin !== '' && this.state.apiKey !== '' &&
      <AppProvider i18n={{}}>
        <Provider config={config}>
          <PageComponent type="new" componentType={this.props.match.params.componentType}></PageComponent>
        </Provider>
      </AppProvider>
    );
  }
}

export default NewPageComponentPage;

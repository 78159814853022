import { ChromePicker } from 'react-color';
import React, { Component, useRef, CSSProperties } from 'react';
import reactCSS from 'reactcss';

interface ColorPickerProps {
  label: string, 
  id: string,
  color: any,
  onChange: Function
}
  
interface ColorPickerState {
  displayColorPicker: boolean,
  color: any
}

class ColorPicker extends Component<ColorPickerProps, ColorPickerState> {
    constructor(props: ColorPickerProps) {
        super(props);
        this.state = {
            displayColorPicker: false,
            color: this.props.color,
        };
    }
    
      handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
      };
    
      handleClose = () => {
        this.setState({ displayColorPicker: false })
      };
    
      handleChange = (color:any) => {
        this.setState({ color: color.rgb })
        this.props.onChange(color.rgb, this.props.id);
      };
    
      render() {
        const colorStyles: CSSProperties = {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `rgba(${ this.props.color.r }, ${ this.props.color.g }, ${ this.props.color.b }, ${ this.props.color.a })`,
          top: '5px',
          left: '5px',
          position: 'absolute',
          zIndex: 2
        };

        const swatchStyles: CSSProperties = {
          width: '46px',
          height: '24px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
          position: 'relative'
        };

        const popoverStyles: CSSProperties = {
          position: 'absolute',
          zIndex: 30,
        };

        const swatchBg: CSSProperties = {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          backgroundImage: `linear-gradient(45deg, #808080 25%, transparent 25%), 
          linear-gradient(-45deg, #808080 25%, transparent 25%),
          linear-gradient(45deg, transparent 75%, #808080 75%),
          linear-gradient(-45deg, transparent 75%, #808080 75%)`,
          backgroundSize: '12px 12px',
          backgroundPosition: '0 0, 0 6px, 6px -6px, -6px 0px',
          top: '5px',
          left: '5px',
          position: 'absolute'
        }

        const coverStyles: CSSProperties = {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px',
        }

        const styles = reactCSS({
          'default': {
            color: colorStyles,
            swatch: swatchStyles,
            popover: popoverStyles,
            cover: coverStyles,
            swatchBg: swatchBg
          },
        });
    
        return (
          <div>
            <p>{ this.props.label }</p>
            <div style={ styles.swatch } onClick={ this.handleClick }>
              <div style={ styles.color } />
              <div style={ styles.swatchBg } />
            </div>
            { this.state.displayColorPicker ? <div style={ styles.popover }>
              <div style={ styles.cover } onClick={ this.handleClose }/>
              <ChromePicker color={ this.props.color } onChange={ this.handleChange } />
            </div> : null }
          </div>
        )
      }
    }

export default ColorPicker;

/*global yotpo:true*/

import $ from "jquery";
import 'jqueryui';
import './jquery.ui.scrolltabs.js';

let documentSrc = document.currentScript.src;

function initTabs(element, tabsData, theme, uuid, checkOriginal=true) {
        addStyles(theme, element, uuid);
        let scrollOptions = {
            selectTabAfterScroll: false,
            showFirstLastArrows: false,
            hideDefaultArrows: false,
            closable: false,
            showNavWhenNeeded: true
        };
        let accordionOptions = {
            heightStyle: "content",
            collapsible: true,
            icons: { "header": "ui-icon-expand", "activeHeader": "ui-icon-contract"},
            create: () => {
                triggerResize();
            },
            activate: function(event, ui) {
                try {
                    let theOffset = ui.newHeader.offset();
                    if ($('html').scrollTop() > theOffset.top) {
                        $('body,html').animate({ 
                            scrollTop: theOffset.top 
                        });
                    }
                } catch(err){}
            }
        }
        if (theme.accordionDefaultCollapsed) {
            accordionOptions.active = false;
        } else {
            accordionOptions.active = 0;
        }

        if (theme.accordionAllowMultipleExpand && !theme.accordionDefaultCollapsed && theme.accordionDefaultExpandAll) {
            accordionOptions.active = false;
        }

        if (theme.accordionAllowMultipleExpand) {
            accordionOptions.beforeActivate = function(event, ui) {
                // The accordion believes a panel is being opened
               if (ui.newHeader[0]) {
                   var currHeader  = ui.newHeader;
                   var currContent = currHeader.next('.ui-accordion-content');
                // The accordion believes a panel is being closed
               } else {
                   var currHeader  = ui.oldHeader;
                   var currContent = currHeader.next('.ui-accordion-content');
               }
                // Since we've changed the default behavior, this detects the actual status
               var isPanelSelected = currHeader.attr('aria-selected') == 'true';
   
                // Toggle the panel's header
               currHeader.toggleClass('ui-corner-all',isPanelSelected).toggleClass('ui-accordion-header-active accordion-header-active ui-state-active ui-corner-top',!isPanelSelected).attr('aria-selected',((!isPanelSelected).toString()));
   
               // Toggle the panel's icon
               currHeader.children('.ui-icon').toggleClass('ui-icon-triangle-1-e ui-icon-expand',isPanelSelected).toggleClass('ui-icon-triangle-1-s ui-icon-contract',!isPanelSelected);
   
                // Toggle the panel's content
               currContent.toggleClass('accordion-content-active',!isPanelSelected)    
               if (isPanelSelected) { currContent.slideUp(); }  else { currContent.slideDown(); }
   
               return false; // Cancels the default action
           }
        }

        let tabOptions = {
            create: () => {
                triggerResize();
            },
            heightStyle: "content",
            scrollOptions: scrollOptions,
            activate: (event, ui) => {
                event.stopPropagation();
            }
        }

        let $subHeaderDesktop = $('<h4>').addClass('vw-subheader').text('DESKTOP');
        let $subHeaderMobile = $('<h4>').addClass('vw-subheader').text('MOBILE');

        let documentSrcSplit = documentSrc.split('?shop=');
        if (documentSrcSplit.length === 2) {
            if (documentSrcSplit[1] === 'olympus-lyfestyle.myshopify.com') {
                accordionOptions.active = false;
            } else if (documentSrcSplit[1] === 'oostendorp.myshopify.com' && !theme.isPreview) {
                injectStyles(`
                    .vw-product-tabs-mobile {
                        display: none !important;
                    }
                    .vw-product-tabs-desktop {
                        display: block !important;
                    }
                `);
            }
        }
        let $desktopDom, $mobileDom;

        if ($(window).width() > 576) { //hack to change sequence
            if (theme.desktopType === 'tabs') {
                $desktopDom = getTabsDOMFromTabsData(tabsData, theme.isPreview, 'desktop');
                $desktopDom.scrollTabs(tabOptions);
            } else if (theme.desktopType === 'accordion') {
                $desktopDom = getAccordionDOMFromTabsData(tabsData, theme.isPreview, 'desktop');
                $desktopDom.accordion(accordionOptions);
            }

            if (theme.mobileType === 'tabs') {
                $mobileDom = getTabsDOMFromTabsData(tabsData, theme.isPreview, 'mobile');
                $mobileDom.scrollTabs(tabOptions);
            } else if (theme.mobileType === 'accordion') {
                $mobileDom = getAccordionDOMFromTabsData(tabsData, theme.isPreview, 'mobile');
                $mobileDom.accordion(accordionOptions);
            }
        } else {
            if (theme.mobileType === 'tabs') {
                $mobileDom = getTabsDOMFromTabsData(tabsData, theme.isPreview, 'mobile');
                $mobileDom.scrollTabs(tabOptions);
            } else if (theme.mobileType === 'accordion') {
                $mobileDom = getAccordionDOMFromTabsData(tabsData, theme.isPreview, 'mobile');
                $mobileDom.accordion(accordionOptions);
            }

            if (theme.desktopType === 'tabs') {
                $desktopDom = getTabsDOMFromTabsData(tabsData, theme.isPreview, 'desktop');
                $desktopDom.scrollTabs(tabOptions);
            } else if (theme.desktopType === 'accordion') {
                $desktopDom = getAccordionDOMFromTabsData(tabsData, theme.isPreview, 'desktop');
                $desktopDom.accordion(accordionOptions);
            }
        }

        if (theme.accordionAllowMultipleExpand && !theme.accordionDefaultCollapsed && theme.accordionDefaultExpandAll) {
            if (theme.mobileType === 'accordion') {
                let numOfPanels = $mobileDom.find('p.ui-accordion-header').length;
                for (let i=0; i<numOfPanels; i++) {
                    $mobileDom.accordion("option", "active", i);
                }
            }
            if (theme.desktopType === 'accordion') {
                let numOfPanels = $desktopDom.find('p.ui-accordion-header').length;
                for (let i=0; i<numOfPanels; i++) {
                    $desktopDom.accordion("option", "active", i);
                }
            }
        }

        let $displayAboveDom = getDisplayAboveDom(tabsData, theme.isPreview);

        if (theme.isPreview) {
            $(element).append($subHeaderDesktop);
            $(element).append($displayAboveDom.clone());
            $(element).append($desktopDom);
            $(element).append('<hr>');
            $(element).append($subHeaderMobile);
            $(element).append($displayAboveDom.clone());
            $(element).append($mobileDom);
        } else {
            if ($displayAboveDom.children().length > 0) {
                if ($('.vw-product-description-above').length === 0) {
                    $(element).append($displayAboveDom);
                } else {
                    $('.vw-product-description-above').append($displayAboveDom);
                }
            }
            if ($('.vw-product-tabs-override').length > 0) {
                $('.vw-product-tabs-override').append($desktopDom);
                $('.vw-product-tabs-override').append($mobileDom);
            } else {
                $(element).append($desktopDom);
                $(element).append($mobileDom);
            }
            $(element).trigger('loaded');
        }

        

        //trigger resize
        function triggerResize() {
            setTimeout(()=> {
                let evt = window.document.createEvent('UIEvents'); 
                evt.initUIEvent('resize', true, false, window, 0); 
                window.dispatchEvent(evt);
            }, 1000);
        }

        function getDisplayAboveDom(tabsData, isPreview) {
            let $main = $('<div>', {
                class: 'vw-display-above',
            });
            let productDescriptionTabExists = false;
            for (let tab of tabsData) {
                if (tab.contentType === 'product_description') {
                    //tab.html = tab.html.replace()
                    productDescriptionTabExists = true;
                }
                if (tab.displayAbove) {
                    let $div = $('<div>');
                    let tabHtml = unescapeHtml(tab.html);
                    if (isPreview) {
                        $div.html('<p>(Description of the product will be shown here)</p>');
                    } else {
                        $div.html(tabHtml);
                    }
                    $main.append($div);
                } else if (tab.displayFirstTabAbove) {
                    let $mainDiv = $('<div>');
                    if (isPreview) {
                        $mainDiv.html('<p>(Description of the first tab will be shown here)</p>');
                        $main.append($mainDiv);
                    } else if (tab.header !== 'none') {
                        let separator = tab.header;
                        let tabHtml = unescapeHtml(tab.html);
                        let $div = $('<div>').html(tabHtml);
                        $div.find('meta').remove();
                        let $children = $div.contents();
                        for (let i=0; i<$children.length; i++) {
                            let $child = $children.eq(i);
                            let tagName = $child.prop("tagName");
                            if (tagName) {
                                tagName = tagName.toLowerCase();
                            }
                            if (tagName === separator) {
                                if (i===0) {
                                    $mainDiv.append($child);
                                } else {
                                    break;
                                }
                            }
                            $mainDiv.append($child);
                        }
                        if ($children.length === 0 && tabHtml) {
                            $mainDiv.html(tabHtml);
                        }
                        $main.append($mainDiv);
                    }
                }
            }

            if (!productDescriptionTabExists && !isPreview && $('#vw-custom-product-tag').length>0 && checkOriginal) {
                let data = JSON.parse($('#vw-custom-product-tag').text().replace(/\t/g, ' '));
                let productDescription = data.product_description;
                if (productDescription) {
                    let productDescriptionHtml = unescapeHtml(productDescription)
                    $main.append(productDescriptionHtml);
                }
            }
            return $main;
        }

        function getTabsDOMFromTabsData(tabsData, isPreview, cl) {
            let main = $('<div>', {
                class: 'vw-product-tabs vw-product-tabs-' + cl,
            });

            let linksContainer = $('<ul>', {
                class: 'vw-product-tabs-links-list',
            });

            let index = 0;
            for (let tab of tabsData) {
                if (!tab.enabled || tab.displayAbove) {
                    continue;
                }
                let tabHtml = unescapeHtml(tab.html);
                if (!isPreview && tab.header !== 'none' && tab.contentType === 'product_description') {
                    let pastFirstTab = false;
                    let separator = tab.header;
                    let $div = $('<div>').html(tabHtml);
                    // remove empty elements before separator?
                    $div.find('meta').remove();
                    let $children = $div.contents();
                    for (let i=0; i<$children.length; i++) {
                        let $child = $children.eq(i);
                        let tagName = $child.prop("tagName");
                        if (tagName) {
                            tagName = tagName.toLowerCase();
                        }
                        if (tagName === separator) {
                            if (!pastFirstTab && tab.displayFirstTabAbove) {
                                pastFirstTab = true;
                                continue;
                            }
                            pastFirstTab = true;
                            index++;
                            let liElement = $('<li>');
                            let aElement = $('<a>').attr('href', '#'+cl+'-product-tab-' + index).text($child.text());
                            liElement.append(aElement);
                            linksContainer.append(liElement);
                        } else {
                            if (i === 0) {
                                // use tab title if does not start with header separator
                                if (!pastFirstTab && tab.displayFirstTabAbove) {
                                    pastFirstTab = true;
                                    continue;
                                }
                                pastFirstTab = true;
                                index++;
                                let liElement = $('<li>');
                                let aElement = $('<a>').attr('href', '#'+cl+'-product-tab-'+index).text(tab.tabTitle);
                                liElement.append(aElement);
                                linksContainer.append(liElement);
                            } 
                            if (index === 0 && tab.displayFirstTabAbove) {
                                continue;
                            }
                            let $contentElement = main.find('#'+cl+'-product-tab-'+index);
                            if (!$contentElement.length) {
                                $contentElement = $('<div>').attr('id', cl+'-product-tab-'+index);
                                main.append($contentElement);
                            }
                            $contentElement.append($child);
                        }
                    }
                    if ($children.length === 0 && tabHtml && !tab.displayFirstTabAbove) {
                        index++;
                        let liElement = $('<li>');
                        let aElement = $('<a>').attr('href', '#'+cl+'-product-tab-'+index).text(tab.tabTitle);
                        liElement.append(aElement);
                        linksContainer.append(liElement);
                        let contentElement = $('<div>').attr('id', cl+'-product-tab-'+index);
                        contentElement.html(tabHtml);
                        main.append(contentElement);
                    }
                } else {
                    index++;
                    let liElement = $('<li>');
                    let aElement = $('<a>').attr('href', '#'+cl+'-product-tab-'+index).text(tab.tabTitle);
                    liElement.append(aElement);
                    linksContainer.append(liElement);
                    let contentElement = $('<div>').attr('id', cl+'-product-tab-'+index);
                    if (tab.contentType === 'static') {
                        if (isPreview) {
                            contentElement.html(tab.html);
                        } else {
                            contentElement.html(tabHtml);
                        }
                    } else if (tab.contentType === 'product_description') {
                        if (isPreview) {
                            contentElement.html('<p>(Description of the product will be shown here)</p>');
                        } else {
                            contentElement.html(tabHtml);
                        }
                    } else if (tab.contentType === 'app_integration') {
                        if (isPreview) {
                            contentElement.html('<p>(Contents of the integrated app will be shown here)</p>');
                        } else {
                            if ((cl === 'desktop' && $(window).width() > 576 ) || (cl === 'mobile' && $(window).width() <= 576)) {
                                let d = $('#vw-app-integration-tab-'+tab.id).detach();
                                d.show();
                                contentElement.append(d);
                            } else {
                                contentElement.html($('#vw-app-integration-tab-'+tab.id).html());
                            }
                        }
                    }
                    main.append(contentElement);
                }
            }
            main.prepend(linksContainer);
            return main;
        }

        function getAccordionDOMFromTabsData(tabsData, isPreview, cl) {
            let main = $('<div>', {
                class: 'vw-product-accordion vw-product-tabs-' + cl,
            });
            let index = 0;
            for (let tab of tabsData) {
                let pastFirstTab = false;
                if (!tab.enabled || tab.displayAbove) {
                    continue;
                }
                let tabHtml = unescapeHtml(tab.html);
                if (!isPreview && tab.header !== 'none' && tab.contentType === 'product_description') {
                    let separator = tab.header;
                    let $div = $('<div>').html(tabHtml);
                    // remove empty elements before separator?
                    $div.find('meta').remove();
                    let $children = $div.contents()
                    for (let i=0; i<$children.length; i++) {
                        let $child = $children.eq(i);
                        let tagName = $child.prop("tagName");
                        if (tagName) {
                            tagName = tagName.toLowerCase();
                        }
                        if (tagName === separator) {
                            if (!pastFirstTab && tab.displayFirstTabAbove) {
                                pastFirstTab = true;
                                continue;
                            }
                            pastFirstTab = true;
                            let h3Element = $('<p>').text($child.text());
                            main.append(h3Element);
                            index++;
                        } else {
                            if (i===0) {
                                if (!pastFirstTab && tab.displayFirstTabAbove) {
                                    pastFirstTab = true;
                                    continue;
                                }
                                pastFirstTab = true;
                                let h3Element = $('<p>').text(tab.tabTitle);
                                main.append(h3Element);
                                index++;
                            }
                            if (index === 0 && tab.displayFirstTabAbove) {
                                continue;
                            }
                            let $divElement = main.find('#'+cl+'-product-accordion-'+index);
                            if (!$divElement.length) {
                                $divElement = $('<div>').attr('id', cl+'-product-accordion-'+index);
                                main.append($divElement);
                            }
                            $divElement.append($child);
                        }
                    }
                    if ($children.length === 0 && tabHtml && !tab.displayFirstTabAbove) {
                        let h3Element = $('<p>').text(tab.tabTitle);
                        main.append(h3Element);
                        let $divElement = $('<div>').attr('id', cl+'-product-accordion-'+index);
                        $divElement.html(tabHtml);
                        main.append($divElement);
                        index++;
                    }
                } else {
                    let h3Element = $('<p>').text(tab.tabTitle).attr('data-title', tab.tabTitle);
                    let divElement = $('<div>');
                    if (tab.contentType === 'static') {
                        if (isPreview) {
                            divElement.html(tab.html);
                        } else {
                            divElement.html(tabHtml);
                        }
                    } else if (tab.contentType === 'product_description') {
                        if (isPreview) {
                            divElement.html('<p>(Description of the product will be shown here)</p>');
                        } else {
                            divElement.html(tabHtml);
                        }
                    } else if (tab.contentType === 'app_integration') {
                        if (isPreview) {
                            divElement.html('<p>(Contents of the integrated app will be shown here)</p>');
                        } else {
                            if ((cl === 'desktop' && $(window).width() > 576 ) || (cl === 'mobile' && $(window).width() <= 576)) {
                                let d = $('#vw-app-integration-tab-'+tab.id).detach();
                                d.show();
                                divElement.append(d);
                            } else {
                                divElement.html($('#vw-app-integration-tab-'+tab.id).html());
                            }
                        }
                    }
                    main.append(h3Element);
                    main.append(divElement);
                }
            };
            return main;
        }

}

function unescapeHtml(safe) {
    return $('<div />').html(safe).text();
}

function destroyTabs(element) {
    $(element).empty();
}


function resetTabs(element, tabs, theme) {
    $(element).empty();
    initTabs(element, tabs, theme);
}

function resetStyles(element, theme) {
    addStyles(theme, element);
}

function addStyles(theme, element, uuid='') {
    if (uuid === '') {
        $(element).find('.vw-product-tab-styles').remove();
        $("<div />", {
            class: 'vw-product-tab-styles',
            html: '<style></style>'
        }).appendTo(element);
    } else {
        let $e = $('[data-id="' + uuid + '"]');
        if ($e.length === 0) {
            $e = $('.id' + uuid);
        } 
        $e.find('.vw-product-tab-styles').remove();
        $("<div />", {
            class: 'vw-product-tab-styles',
            html: '<style></style>'
        }).appendTo($e);
    }
    
    injectStyles('.vw-product-tabs.ui-tabs li.ui-state-default, .vw-product-tabs.ui-tabs li.ui-state-hover, .vw-product-tabs.ui-tabs .ui-widget-header, .vw-product-tabs.ui-widget-content {' + 'border-color: ' + getColorString(theme.strokeColor) + '}', element, uuid);
    injectStyles('.vw-product-accordion.ui-accordion .ui-state-default, .vw-product-accordion.ui-accordion .ui-accordion-header.ui-state-hover, .vw-product-accordion.ui-accordion .ui-accordion-header-active, .vw-product-accordion.ui-accordion .ui-widget-content {' + 'border-color: ' + getColorString(theme.strokeColor) + '}', element, uuid);
    injectStyles('.vw-product-tabs.ui-tabs li.ui-state-default, .vw-product-accordion.ui-accordion .ui-accordion-header.ui-state-default {' + 'background: ' + getColorString(theme.defaultBgColor) + '}', element, uuid);
    injectStyles('.vw-product-tabs.ui-tabs li.ui-state-default a, .vw-product-accordion.ui-accordion .ui-accordion-header.ui-state-default {' + 'color: ' + getColorString(theme.defaultColor) + '; font-weight:' +  theme.defaultFontWeight +'}', element, uuid);
    injectStyles('.vw-product-tabs.ui-tabs .ui-tabs-active, .vw-product-accordion.ui-accordion .ui-accordion-header-active {' + 'background: ' + getColorString(theme.activeBgColor) + '!important }', element, uuid);
    injectStyles('.vw-product-tabs.ui-tabs li.ui-state-active a, .vw-product-accordion.ui-accordion .ui-accordion-header-active {' + 'color: ' + getColorString(theme.activeColor) + '!important; font-weight:' + theme.activeFontWeight + '!important }', element, uuid);
    injectStyles('.vw-product-tabs.ui-tabs .ui-tabs-panel {' + 'background: ' + getColorString(theme.bgColor) + '}', element, uuid);
    injectStyles('.vw-product-tabs.ui-tabs .ui-tabs-panel, .vw-product-accordion.ui-accordion .ui-accordion-content {' + 'color: ' + getColorString(theme.textColor) + '}', element, uuid);
    injectStyles('.vw-product-tabs.ui-tabs .ui-tabs-panel, .vw-product-accordion.ui-accordion .ui-accordion-content {' + 'background: ' + getColorString(theme.bgColor) + '}', element, uuid);
    
    injectStyles('.vw-product-tabs.ui-tabs .ui-tabs-nav .ui-tabs-anchor, .vw-product-tabs.ui-tabs .ui-tabs-nav .ui-tabs-anchor::after {border-bottom:0 !important;}', element, uuid);

    injectStyles('.vw-display-above { margin-bottom:20px; }', element, uuid);

    if (theme.style === 'normal') {
        injectStyles('.vw-product-tabs.ui-tabs .ui-tabs-panel, .vw-product-accordion.ui-accordion .ui-accordion-content { padding-left: 1em !important; padding-right: 1em !important; }', element, uuid);
        injectStyles('.vw-product-tabs.ui-tabs .ui-tabs-panel { border: 1px solid ' + getColorString(theme.strokeColor) + '; margin-top: -1px;}', element, uuid);
        injectStyles('.vw-product-tabs.ui-tabs .ui-tabs-nav { border-bottom: 0; padding: .2em .2em 0 0; }', element, uuid);
    }

    if (theme.style === 'box') {
        injectStyles('.ui-tabs .ui-tabs-nav { border-bottom: 0; padding: .2em .2em 0 0; }', element, uuid);
        injectStyles('.vw-product-tabs.ui-tabs .ui-tabs-panel, .vw-product-accordion.ui-accordion .ui-accordion-content { padding-left: 1em !important; padding-right: 1em !important; }', element, uuid);
        injectStyles('.vw-product-tabs.ui-tabs .vw-product-tabs-links-list li { margin-right:0; }', element, uuid)
        injectStyles('.vw-product-accordion.ui-accordion .ui-accordion-header { margin-top:0; }', element, uuid);
    }

    if (theme.style === 'underline' || theme.style === 'pill' || theme.style === 'box' || theme.style === 'link') {
        injectStyles('.vw-product-tabs.ui-tabs li.ui-state-default, .vw-product-tabs.ui-tabs li.ui-state-hover, .vw-product-tabs.ui-tabs .ui-widget-header, .vw-product-tabs.ui-widget-content {' + 'border: 0 }', element, uuid);
        injectStyles('.vw-product-tabs.ui-tabs li.ui-state-default { border-radius: 0 }', element, uuid);
        injectStyles('.vw-product-accordion.ui-accordion .ui-state-default, .vw-product-accordion.ui-accordion .ui-accordion-header.ui-state-hover, .vw-product-accordion.ui-accordion .ui-accordion-header-active, .vw-product-accordion.ui-accordion .ui-widget-content {' + 'border: 0}', element, uuid);
        injectStyles('.vw-product-tabs.ui-tabs .ui-tabs-panel { border-radius:0; border-bottom: 1px solid ' + getColorString(theme.strokeColor) + '}', element, uuid);
        injectStyles('.vw-product-accordion.ui-accordion .ui-accordion-content, .vw-product-accordion.ui-accordion .ui-accordion-header.ui-state-hover, .vw-product-accordion.ui-accordion .ui-accordion-header { border-radius:0; border-bottom: 1px solid ' + getColorString(theme.strokeColor) + '}', element, uuid);
    }

    if (theme.style === "underline") {
        injectStyles('.vw-product-tabs.ui-tabs .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor {border-bottom: 3px solid '+ getColorString(theme.activeColor) + ' !important; padding-left: 0 !important; padding-right: 0 !important; margin-left: 1em; margin-right: 1em;}', element, uuid);
        injectStyles('.vw-product-tabs.ui-tabs .ui-tabs-nav li:not(.ui-state-active) { top: -3px;}', element, uuid)
        injectStyles('.vw-product-accordion.ui-accordion .ui-accordion-header, .vw-product-accordion.ui-accordion .ui-accordion-header.ui-state-hover, .vw-product-accordion.ui-accordion .ui-accordion-content {border-bottom: 3px solid '+ getColorString(theme.defaultColor) + '}', element, uuid)
        injectStyles('.vw-product-accordion.ui-accordion .ui-accordion-header.ui-state-active {border-bottom: 3px solid '+ getColorString(theme.activeColor) + ' !important;}', element, uuid);
        injectStyles('.vw-product-accordion.ui-accordion .ui-accordion-header { padding-left: 0;}', element, uuid);
        injectStyles('.vw-product-accordion.ui-accordion .ui-accordion-header .ui-accordion-header-icon { left:initial; right: 0;}', element, uuid);
    }

    if (theme.style === "pill") {
        injectStyles('.vw-product-tabs.ui-tabs li.ui-state-default { border-radius: 40px; margin-right: 7px; }', element, uuid);
        injectStyles('.vw-product-accordion.ui-accordion .ui-accordion-header { border-radius: 40px !important; border-bottom: 0 !important; margin-bottom:8px;}', element, uuid);
        injectStyles('.vw-product-tabs.ui-tabs .vw-product-tabs-links-list .ui-state-default {  line-height: 10px; }', element, uuid);
        injectStyles('.vw-product-accordion.ui-accordion .ui-accordion-header .ui-accordion-header-icon { margin-left: 12px; }', element, uuid);
        injectStyles('.vw-product-accordion.ui-accordion.ui-accordion .ui-accordion-header {padding-left: 2.8em !important}', element, uuid);
        injectStyles('.vw-product-tabs.ui-tabs .ui-tabs-nav .ui-tabs-anchor{padding:.7em 1em !important;}', element, uuid);
    }

    if (theme.style === "link") {
        injectStyles('.vw-product-tabs.ui-tabs .ui-tabs-anchor, .vw-product-accordion.ui-accordion .ui-accordion-header { text-decoration: underline !important; }', element, uuid);
        injectStyles('.vw-product-tabs.ui-tabs .vw-product-tabs-links-list .ui-state-default {  line-height: 0; margin-right:0; border-right: 1px solid '+ getColorString(theme.strokeColor) + '}', element, uuid);
        injectStyles('.vw-product-tabs.ui-tabs .vw-product-tabs-links-list .ui-state-default:last-of-type { border-right:0 !important; }', element, uuid);
    }

    if (theme.style === "link" || theme.style === "underline") {
        injectStyles('.vw-product-tabs.ui-tabs .vw-product-tabs-links-list .ui-state-default:first-of-type .ui-tabs-anchor { padding-left:0; margin-left:0; }', element, uuid);
    }

    if (theme.iconType === 'none') {
        injectStyles('.vw-product-accordion.ui-accordion .ui-accordion-header .ui-accordion-header-icon.ui-icon-expand { display:none; }', element, uuid);
        injectStyles('.vw-product-accordion.ui-accordion .ui-accordion-header .ui-accordion-header-icon.ui-icon-contract { display:none; }', element, uuid);
    } else if (theme.iconType === 'plus') {
        injectStyles(`
        .vw-product-accordion.ui-accordion .ui-accordion-header .ui-accordion-header-icon.ui-icon-expand { 
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="` + getColorString(theme.iconDefaultColor) + `" d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"/></svg>');
        }
        .vw-product-accordion.ui-accordion .ui-accordion-header .ui-accordion-header-icon.ui-icon-contract { 
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="` + getColorString(theme.iconActiveColor) + `" d="M0 10h24v4h-24z"/></svg>');
        }
        `, element, uuid)
    } else if (theme.iconType === 'arrow') {
        injectStyles(`
        .vw-product-accordion.ui-accordion .ui-accordion-header .ui-accordion-header-icon.ui-icon-expand { 
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="` + getColorString(theme.iconDefaultColor) + `" d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>');
        }
        .vw-product-accordion.ui-accordion .ui-accordion-header .ui-accordion-header-icon.ui-icon-contract { 
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="` + getColorString(theme.iconActiveColor) + `" d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z"/></svg>');
        }
        `, element, uuid)
    }

    injectStyles(`
    .vw-product-tabs.ui-tabs .ui-icon.ui-icon-seek-next{
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="` + getColorString(theme.iconDefaultColor) + `" d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/></svg>');
    }
    .vw-product-tabs.ui-tabs .stNavMain button {
        background: ` + getColorString(theme.defaultBgColor) + `;
    }
    .vw-product-tabs.ui-tabs .ui-icon.ui-icon-seek-prev{
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="` + getColorString(theme.iconDefaultColor) + `" d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>');
    }
    `, element, uuid);
}

function injectStyles(rule, element, uuid) {
    if (uuid !== '') {
        if ($('[data-id="' + uuid + '"]').length === 0) {
            rule = rule.split('.vw-product-tabs.').join('.id' + uuid + ' .vw-product-tabs.');
            rule = rule.split('.vw-product-accordion.').join('.id' + uuid + ' .vw-product-accordion.');
            let html = $('.id' + uuid).find('.vw-product-tab-styles style').html();
            $('.id' + uuid).find('.vw-product-tab-styles style').html(html + ' ' + rule);
        } else {
            rule = rule.split('.vw-product-tabs.').join('[data-id="' + uuid + '"] .vw-product-tabs.');
            rule = rule.split('.vw-product-accordion.').join('[data-id="' + uuid + '"] .vw-product-accordion.');
            let html = $('[data-id="' + uuid + '"]').find('.vw-product-tab-styles style').html();
            $('[data-id="' + uuid + '"]').find('.vw-product-tab-styles style').html(html + ' ' + rule);
        }
    } else {
        let html = $(element).find('.vw-product-tab-styles style').html();
        $(element).find('.vw-product-tab-styles style').html(html + ' ' + rule);
    }
}

function getColorString(color) {
    let s = 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')';
    return s; 
}

export { initTabs, destroyTabs, resetTabs, resetStyles };